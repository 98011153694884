<template lang="pug">
.container
  .box
    h3.title.is-4(v-t="'customers.merge_title'")
    p.subtitle.is-6(v-t="'customers.merge_subtitle'")
    .is-flex.is-align-items-center.is-justify-content-space-between.mb-4
      div(style="flex: 1 1 0")
        customer-tiny-preview.notification.p-3(:value="mergingCustomer")
      div.has-text-centered(style="flex: 1 1 0")
        i.mdi.mdi-arrow-left-bold.mdi-24px
      div(style="flex: 1 1 0" v-if="mergeWithCustomer && mergeWithCustomer !== mergingCustomer")
        customer-tiny-preview.notification.p-3( :value="mergeWithCustomer")
      .notification.is-warning(style="flex: 1 1 0;" v-else)
        p(v-t="'customers.merge_select_client'")
    .buttons
      action-button.is-black(@click="confirmMerge" :disabled="!mergingCustomer || !mergeWithCustomer" title="customers.actions.merge_account" icon="call-merge" permission="CLIENTS_MERGE")
      button.button(@click="cancelMerge" v-t="'actions.cancel'")
</template>

<script>
import CustomersApi from '@/api/customers'

export default {
  name: 'CustomerMerger',
  props: {
    mergingCustomer: {type: Object, default: undefined},
    mergeWithCustomer: {type: Object, default: undefined}
  },
  methods: {
    cancelMerge() {
      this.mergingCustomer = undefined
      this.mergeWithCustomer = undefined
      this.$emit('cancel')
    },
    confirmMerge() {
      if (!this.mergingCustomer || !this.mergeWithCustomer || this.mergingCustomer === this.mergeWithCustomer) {
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Merging customer accounts',
        message: `This will merge "${this.mergingCustomer.firstName} ${this.mergingCustomer.lastName}" ${
          this.mergingCustomer.sender ? 'Messenger' : 'manual'
        } account with "${this.mergeWithCustomer.firstName} ${this.mergeWithCustomer.lastName}" ${
          this.mergeWithCustomer.sender ? 'Messenger' : 'manual'
        } account into one. This operation cannot be undone. Do you want to continue?`,
        type: 'is-danger',
        confirmText: 'Merge',
        onConfirm: async () => {
          try {
            await CustomersApi.merge(this.mergingCustomer, this.mergeWithCustomer)
            this.$buefy.toast.open({message: 'Merging successful', type: 'is-success', queue: false})
            this.$emit('merged')
          } catch (error) {
            this.$handleAPIError(error)
            this.$emit('mergeFailed')
          }
        }
      })
    }
  }
}
</script>
