import io from 'socket.io-client'

export default {
  init(store) {
    let url
    if (process.env.NODE_ENV === 'production') {
      url = '/'
    } else if (process.env.NODE_ENV === 'test') {
      url = 'http://localhost:7777'
    } else {
      url = 'http://localhost:3000'
    }
    const socket = io(url, {
      transports: ['websocket'],
      autoConnect: false
    })

    store.subscribe((mutation, state) => {
      if (mutation.type === 'auth/SET_USER') {
        if (state.auth.user) {
          socket.io.opts.query = {
            ws_token: store.state.auth.token
          }
          socket.open()
        }
      } else if (mutation.type === 'auth/LOGOUT') {
        socket.io.opts.query = {
          ws_token: null
        }
        socket.close()
        console.log('socket disconnected')
      }
    })
    return socket
  }
}
