<template lang="pug">
  span.is-printable(:class="[isTag ? 'tag is-rounded' : '', 'is-' + colorClass, tagSize]")
    span.icon(v-if="!showText")
      i.mdi(:class="[!isTag ? 'has-text-' + colorClass : '', 'mdi-' + icon]")
    b.px-1(v-if="showText" style="text-transform: uppercase") {{$t(`payment_status.${status}`)}}
</template>

<script>
import PAYMENT_STATUS from '../../../constants/paymentStatus'

export default {
  props: {
    status: {type: String, required: true},
    showText: {type: Boolean, default: false},
    isTag: {type: Boolean, default: false},
    tagSize: {type: String, default: undefined}
  },
  computed: {
    colorClass() {
      switch (this.status) {
        case PAYMENT_STATUS.PAID:
          return 'success'
        case PAYMENT_STATUS.PARTIALLY_PAID:
          return 'warning'
        case PAYMENT_STATUS.UNPAID:
          return 'danger'
        case PAYMENT_STATUS.REFUNDED:
          return 'danger'
        default:
          return ''
      }
    },
    icon() {
      switch (this.status) {
        case PAYMENT_STATUS.PAID:
          return 'check'
        case PAYMENT_STATUS.PARTIALLY_PAID:
          return 'check'
        case PAYMENT_STATUS.UNPAID:
          return 'close'
        case PAYMENT_STATUS.REFUNDED:
          return 'cash-refund'
        default:
          return ''
      }
    }
  }
}
</script>
