<template lang="pug">
  div(data-qa="staff-members")
    screen-title(title="staff.title_members")
      action-button(@click="openAddMemberModal" title="staff.actions.add_member" icon="account-plus" permission="STAFF_ADD" data-qa="action-add-staff-member")
    .columns
      .column
        b-table(
          :data="staffMembers"
          custom-row-key="_id"
          draggable
          @dragstart="dragstart"
          @drop="drop"
          @dragover="dragover"
          @dragleave="dragleave"
          
          :loading="loading" 
          class="is-vertically-aligned" 
          hoverable=true 
          :row-class="(row, index) => !row.active && 'is-disabled'" 
          @click="edit")
          b-table-column(field="name" :label="$t('staff.name_heading')" width="160" v-slot="props")
            .media
              .media-left 
                staff-pic.is-48x48.is-round(:staff="props.row")
              .media-content
                

                p.title.is-6 
                  staff-view(:id="props.row._id")
                p.subtitle.is-6(v-if="props.row.service") {{props.row.role && $t('roles.' + props.row.role)}}
                p.subtitle.is-6.has-text-primary(v-else) 
                  span.icon
                    i.mdi.mdi-key
                  span administrator
                
          b-table-column(field="active" :label="$t('staff.provides_services_heading')" width="20" v-slot="props") 
            p(v-if="props.row.service && props.row.level") 
              experience-view(:staff-id="props.row._id")
            //{{props.row.service ? $t('staff.provides_services_yes') : $t('staff.provides_services_no')}}
            //strong(data-qa="name") {{props.row.firstName}} {{props.row.lastName}}
          //b-table-column(field="personalProfile" :label="$t('staff.facebook_heading')" width="20" v-slot="props") 
            div(v-if="props.row.personalProfile").is-clipped
              customer-tiny-preview.top(:value="props.row.personalProfile" size="small")
          //b-table-column(field="expressions" :label="$t('staff.custom_gifs_heading')" width="20" v-slot="props" :visible="$rfSubscribed('surveys')") 
            .tags(v-if="props.row.expressions")
              span.tag.is-warning(v-for="(gifs, key) in props.row.expressions" v-if="gifs && gifs.length") {{$t(`expressions.${key}`)}}: {{gifs.length}}
          b-table-column(field="location" :label="$t('staff.location_heading')" width="20" v-slot="props" :visible="hasMultipleLocations") 
            location-view(:value="props.row.location")
            template(v-if="props.row.locations && props.row.locations.length")
              p.is-size-7(v-for="loc in props.row.locations.filter(l => l !== props.row.location)" :key="loc") 
                location-view(:value="loc")
          
          //b-table-column(field="role" :label="$t('staff.role_heading')" width="20" v-slot="props") {{props.row.role && $t('roles.' + props.row.role)}}
          b-table-column(field="access" :label="$t('staff.access_heading')" width="20" v-slot="props") 
            span.icon.has-text-danger(v-if="props.row.access === 'no'") 
              i.mdi.mdi-cancel
            span(v-else) {{$t(`access.${props.row.access}`)}} 
            template(v-if="props.row.scope !== 'all'")
              p.help {{$t(`staff_access_scope.${props.row.scope}.title`)}}
          
          b-table-column(field="active" :label="$t('staff.commission_heading')" width="20" v-slot="props") 
            b-tooltip(:label="$t('staff.details.commissions.services_label')")
              percentage-view.is-percent(:value="props.row.commission.services/100") 
            span  /  
            b-tooltip(:label="$t('staff.details.commissions.retail_label')")
              percentage-view.is-percent(:value="props.row.commission.retail/100")

</template>
<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import CreateEditStaffForm from './CreateEditStaffForm'
export default {
  name: 'StaffMembers',
  data() {
    return {
      loading: false,
      draggingRowIndex: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('staff', ['activeMembers', 'activeMembersByLocation']),
    ...mapGetters('location', ['hasMultipleLocations']),
    staffMembers() {
      if (this.$rfLocationScope() || this.$rfProfileScope()) {
        return this.activeMembersByLocation(this.user.location)
      }
      return this.activeMembers
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions('staff', ['list', 'updateMembersOrder']),
    async fetchData() {
      this.loading = true
      try {
        await this.list(true)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    edit(member) {
      this.$buefy.modal.open({
        parent: this,
        component: CreateEditStaffForm,
        fullScreen: true,
        hasModalCard: true,
        canCancel: ['escape'],
        props: {staffId: member._id}
      })
    },
    openAddMemberModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CreateEditStaffForm,
        fullScreen: true,
        hasModalCard: true,
        canCancel: ['escape']
      })
    },
    dragstart(payload) {
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      if (this.draggingRowIndex === payload.index) return
      let order = this.staffMembers.map(s => s._id)
      let draggingId = order[this.draggingRowIndex]

      order.splice(this.draggingRowIndex, 1)
      order.splice(payload.index, 0, draggingId)

      this.updateMembersOrder(order)
    }
  }
}
</script>
