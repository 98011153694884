<template lang="pug">
.modal-card
    header.modal-card-head
      p.modal-card-title
        b(v-t="'vouchers.redeem_voucher_title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
        
        form(v-on:submit.prevent="search")
          button.is-hidden(type="submit")
          b-field(expanded)
            b-input(v-model="query" size="is-medium" ref="search" @input="search" expanded icon="magnify" :placeholder="$t('vouchers.search_placeholder')" icon-right="close-circle-outline"
              icon-right-clickable
              @icon-right-click="clearSearch")
         
        .mt-3(v-if="!total" style="min-height: 200px")
          em-loading(:active="searching" :is-full-page="false")
          section.section(v-if="!query && !searching")
            .hero
              .hero-body
                h3.is-size-5.has-text-centered(v-t="'vouchers.search_vouchers_empty_prompt'")
          section.section(v-else-if="!searching")
            .hero
              .hero-body
                h3.is-size-5.has-text-centered(v-t="'vouchers.search_no_results'")
        .mt-3(v-else-if="total > 1 && !voucher")
          note.mb-3
            p(v-t="'vouchers.more_than_one_warning'")
          .list-item.is-flex.is-justify-content-space-between.is-align-items-center(v-for="voucher in vouchers" :key="voucher._id")
            div 
              voucher-name-view(:value="voucher" invoice show-nominal-value show-remainder)
            div
              a.button.is-outlined.is-primary(@click.stop="selectVoucher(voucher)") {{ $t('actions.select') }}
        .mt-3(v-else-if="voucher")
          note.mb-4(type="is-warning" v-if="!isEligible") {{ $t('vouchers.not_eligible_voucher') }}
          b-field(v-else  :label="$t('vouchers.redeem_amount_label')" :type="errors.has('redeem') ? 'is-danger': ''" :message="errors.has('redeem') ? errors.first('redeem') : ''" expanded )
            p.control
              span.button.is-static 
                currency
            b-input(:placeholder="$t('vouchers.redeem_placeholder')" ref="redeemField" :disabled="!isEligible" v-model.number="redeem" name="redeem" v-validate="redeemValidateRules" data-vv-as="value" expanded)
          VoucherView.mb-4(:value="voucher" style="margin:0 auto" expand-services @change="onVoucherUpdate")
          

    footer.modal-card-foot
      .buttons
        action-button(title="actions.redeem" @click="submit" :disabled="!voucher || !isEligible ||isExpired")
        button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.cancel')}}      
</template>
<script>
import VoucherView from './VoucherView'
import VouchersApi from '@/api/vouchers'
import debounce from 'lodash/debounce'
import saleHelper from '../../../shared/saleHelper'

export default {
  name: 'VoucherRedeem',
  components: {
    VoucherView
  },
  props: {
    saleItems: {type: Array, required: true},
    due: {type: Number, required: true}
  },
  data() {
    return {
      voucher: undefined,
      vouchers: [],
      total: undefined,
      query: undefined,
      redeem: undefined,
      searching: false
    }
  },
  computed: {
    redeemValidateRules() {
      return `required|decimal:2|min_value:0|max_value:${Math.min(this.eligiblePrice, this.voucher.remaining)}`
    },
    eligibleItems() {
      return saleHelper.findEligibleVoucherItems({voucher: this.voucher, items: this.saleItems})
    },
    isEligible() {
      if (!this.voucher) {
        return false
      }
      return !!this.eligibleItems.length
    },
    eligiblePrice() {
      return this.eligibleItems.reduce((sum, item) => {
        return sum + item.total
      }, 0)
    },
    isExpired() {
      return new Date(this.voucher.expire) < new Date()
    }
  },
  created() {},
  mounted() {
    this.$refs.search.focus()
  },
  methods: {
    search: debounce(async function() {
      if (!this.query || !this.query.trim().length) {
        this.voucher = undefined
        this.vouchers = []
        this.total = undefined
        return
      }
      this.searching = true
      try {
        this.voucher = undefined
        const {data, total} = await VouchersApi.search({
          code: this.query.trim().toUpperCase(),
          perPage: 100
        })
        this.vouchers = data
        this.total = total
        if (total === 1) {
          this.selectVoucher(data[0])
        }
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.searching = false
    }, 600),
    selectVoucher(voucher) {
      this.voucher = voucher
      this.redeem = Math.min(this.voucher.remaining, this.eligiblePrice, this.due)
    },
    clearSearch() {
      this.query = undefined
      this.voucher = undefined
      this.vouchers = []
      this.total = undefined
    },
    onVoucherUpdate(voucher) {
      Object.assign(this.voucher, voucher)
    },
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.$emit('input', {voucher: this.voucher, redeem: this.redeem})
      this.$emit('close')
    }
  }
}
</script>
<style scoped></style>
