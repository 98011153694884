<template lang="pug">
.media.flex-center(@click="link && showDetails()" :class="link ? 'hoverable':''" v-if="customer")
  .media-left
    ProfilePic(:customer="customer" :class="imageSizeClass")
  .media-content.has-text-left(style="overflow: visible")
      p.is-size-6.is-no-break(v-if="customer.unknown") {{$t('misc.walkin_customer')}}
      p.title.is-6.is-no-break(v-else) 
        customer-name(:value="customer") 
      p.subtitle.is-7.is-no-break.mb-1
        phone-view(:value="customer.phone" :tag="false" v-if="customer.phone" :customer="customer")
        template(v-if="customer.altPhone")
          | ,     
          phone-view(:value="customer.altPhone" :tag="false" :customer="customer")
        |  
        BirthdayTag(:customer="customer" :tag="false" v-if="customer.birthday")
      .tags(v-if="tags && customer.tags && $rfSubscribed(PRODUCTS.CRM_PRO)")
        .tag.is-info.is-small.has-text-weight-semibold( v-for="tag in customer.tags" :key="tag") {{tag}}
        //template(v-if="customer.email && size !== 'small'")
          br
          span.icon.is-small
            i.mdi.mdi-email-outline
          span {{customer.email}}
          
  .media-right(v-if="closable && !customer.unknown")
    a(@click.stop="$emit('close')")
      span.icon.is-medium
        i.mdi.mdi-close.mdi-24px
</template>
<script>
import ProfilePic from '../customers/ProfilePic'
import Details from '../customers/Details'
import BirthdayTag from '../customers/BirthdayTag'
import {PRODUCTS} from '../../../constants/products'
import CustomerDetailsMobile from '../customers/CustomerDetailsMobile.vue'
import {mapActions} from 'vuex'
export default {
  name: 'CustomerTinyPreview',
  components: {
    ProfilePic,
    BirthdayTag
  },
  props: {
    value: {
      type: [String, Object],
      default: undefined
    },
    closable: {type: Boolean, default: false},
    size: {type: String, default: 'normal'},
    link: {type: Boolean, default: false},
    tags: {type: Boolean, default: false}
  },
  data() {
    return {
      customer: undefined,
      PRODUCTS
    }
  },

  computed: {
    imageSizeClass() {
      if (this.size === 'small') {
        return 'is-32x32'
      }
      return 'is-48x48'
    }
  },
  watch: {
    value() {
      this.init()
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    async init() {
      if (this.value && typeof this.value === 'string') {
        this.customer = await this.fetchCustomer(this.value)
      } else if (this.value) {
        this.customer = this.value
      } else {
        this.customer = {unknown: true}
      }
    },
    showDetails() {
      if (!this.customer) {
        return
      }
      this.$buefy.modal.open({
        parent: this,
        component: this.isMobile() ? CustomerDetailsMobile : Details,
        hasModalCard: true,
        fullScreen: true,
        props: {customerId: this.customer._id},
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
<style scoped>
.hoverable {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;
  transition: background-color 200ms ease-in-out;
}
.hoverable:hover {
  background: #f6f6f6;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
}
</style>
