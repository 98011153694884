<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="title")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.has-lightgrey-background
    em-loading(:active="loading")
    .columns.is-centered
      .column.is-8
        .box
          b-field(grouped group-multiline)
            b-field(:label="$t('customer.first_name_label')" expanded)
              b-input(:placeholder="$t('customer.first_name_placeholder')" icon="account-outline" v-model="firstName" ref="firstName" data-qa="first-name")
            
            b-field(:label="$t('customer.last_name_label')" expanded)
              b-input(:placeholder="$t('customer.last_name_placeholder')" icon="account-outline" v-model="lastName" data-qa="last-name")
            b-field(:label="$t('customer.middle_name_label')" expanded v-if="company.crm.middleName")
              b-input(:placeholder="$t('customer.middle_name_placeholder')" icon="account-outline" v-model="middleName" data-qa="middle-name")
          b-field(:label="$t('customer.phone_label')" v-show="$rfHasPermission('PRIVACY_VIEW_PHONE_NUMBERS')" :type="phoneErrorMessage ? 'is-danger': ''" :message="phoneErrorMessage")
            phone-input(v-model="phone" @input="onPhoneInput" name="phone" :fetch-customer="true" style="z-index:10" :placeholder="$t('customer.phone_placeholder')" data-qa="phone" v-validate="company.crm.phoneRequired ? 'required': ''")
          
          b-field(v-if="company.crm.altPhone" :label="$t('customer.alt_phone_label')" v-show="$rfHasPermission('PRIVACY_VIEW_PHONE_NUMBERS')" :type="altPhoneErrorMessage ? 'is-danger': ''" :message="altPhoneErrorMessage")
            phone-input(v-model="altPhone" @input="onAltPhoneInput" name="altPhone"  style="z-index:10" :placeholder="$t('customer.alt_phone_placeholder')" data-qa="phone" )

          b-field(:message="$t('customer.sms_invite_message')" v-if="$rfSubscribed(PRODUCTS.REWARDS_PROGRAM) && !customerId && $rfEnabled(PRODUCTS.REWARDS_PROGRAM)")
            b-checkbox(v-model="sendSmsInvite" :disabled="!this.phone") {{$t('customer.sms_invite_checkbox')}}
          b-field(:label="$t('customer.email_label')" :type="errors.has('email') ? 'is-danger': ''" :message="errors.has('email') ? errors.first('email') : ''")
            b-input(:placeholder="$t('customer.email_placeholder')" type="email" name="email" icon="email-outline" v-model="email" data-qa="email" v-validate="company.crm.emailRequired ? 'required': ''")
          
          b-field(:label="$t('customer.nickname_label')")
            b-input(:placeholder="$t('customer.nickname_placeholder')" icon="account" v-model="internalName")
          b-field(grouped group-multiline)
            b-field(:label="$t('customer.gender_label')")
              b-select(v-model="gender" icon="gender-male-female" :placeholder="$t('customer.gender_placeholder')" data-qa="gender")
                option(v-for="gender in genders" :value="gender") {{$t(`genders.${gender}`)}}
            b-field(:label="$t('customer.birthday_label')")
              localized-datepicker(:placeholder="$t('customer.birthday_placeholder')" icon="calendar" v-model="birthday" editable)
            b-field(:label="$t('customer.language_label')")
              b-select(v-model="lng" icon="earth" :placeholder="$t('customer.language_placeholder')" data-qa="language")
                option(v-for="lng in company.languages" :value="lng") {{$t(`languages.${lng}`)}}
          
          b-field(:label="$t('customer.tags_label')" v-if="$rfSubscribed(PRODUCTS.CRM_PRO)")
            b-taginput(
                v-model="tags"
                :data="company.crm.tags"
                type="is-primary"
                attached
                autocomplete
                ellipsis
                open-on-focus
                maxtags="20"
                icon="label"
                :placeholder="$t('customer.tags_placeholder')")
          p {{ $t('customer.tags_help') }} 
            router-link(to="/settings/crm" target="_blank") {{ $t('misc.here') }}.
          hr
          label.label(v-t="'customer.accepts_marketing_label'")
          b-field
            b-checkbox(v-model="acceptMessage") Messenger
            b-checkbox(v-model="acceptSMS") SMS
            b-checkbox(v-model="acceptEmail") Email
          b-field(:label="$t('customer.additional_label')" v-if="$rfSubscribed(PRODUCTS.PROMOLINKS)")
            b-checkbox(v-model="affiliate") {{$t('customer.affiliate_option')}}
        
        .box(v-if="$rfSubscribed(PRODUCTS.PAYMENTS)")
          h3.title.is-4(v-t="'customer.deposit_settings_title'")
          b-field(:label="$t('customer.deposit_label')")
            b-select(v-model="depositGroup")
              option(v-for="ratio in Object.values(DEPOSIT_GROUP)" :key="ratio" :value="ratio") {{$t(`deposit_ratio.${ratio}`)}}
        
        .box(v-if="$rfSubscribed(PRODUCTS.CRM_PRO) && company.crm.addressFields")
          h3.title.is-4(v-t="'customer.address_heading'")
          b-field(:label="$t('locations_settings.details.street_label')" :type="errors.has('street') ? 'is-danger': ''" :message="errors.has('street') ? errors.first('street') : ''")
            b-input(:placeholder="$t('locations_settings.details.street_placeholder')" data-vv-as="street" name="street" icon="domain" v-model="address.street")
          b-field(:type="errors.has('streetAlt') ? 'is-danger': ''" :message="errors.has('streetAlt') ? errors.first('streetAlt') : ''")
            b-input(:placeholder="$t('locations_settings.details.street_2_placeholder')" data-vv-as="a" name="streetAlt" icon="domain" v-model="address.streetAlt")
          b-field( :label="$t('locations_settings.details.city_label')" :type="errors.has('city') ? 'is-danger': ''" :message="errors.has('city') ? errors.first('city') : ''")
            b-input(:placeholder="$t('locations_settings.details.city_placeholder')"  data-vv-as="city" name="city" icon="domain" v-model="address.city")
          b-field(grouped)
            b-field(:label="$t('locations_settings.details.zip_label')" :type="errors.has('zip') ? 'is-danger': ''" :message="errors.has('zip') ? errors.first('zip') : ''")
              b-input(:placeholder="$t('locations_settings.details.zip_placeholder')" data-vv-as="zip" name="zip" icon="domain" v-model="address.zip")  
            b-field(expanded :label="$t('locations_settings.details.state_label')" :type="errors.has('state') ? 'is-danger': ''" :message="errors.has('state') ? errors.first('state') : ''")
              b-input(:placeholder="$t('locations_settings.details.state_placeholder')" expanded data-vv-as="state" name="state" icon="domain"  v-model="address.state")
        
        .box(v-if="$rfSubscribed(PRODUCTS.REWARDS_PROGRAM)")
          h3.title.is-4(v-t="'customer.rewards_program_heading'")
          b-field(:label="$t('customer.membership_card_label')")
            b-input(:placeholder="$t('customer.membership_card_placeholder')" icon="wallet-membership" v-model="cardId")
        .box
          h3.title.is-4(v-t="'customer.social_account_heading'")
          b-field(:label="$t('customer.instagram_label')")
            b-input(:placeholder="$t('customer.instagram_placeholder')" icon="instagram" v-model="instagram")
          b-field(:label="$t('customer.facebook_label')")
            b-input(:placeholder="$t('customer.facebook_placeholder')" icon="facebook" v-model="facebook" data-qa="facebook")
        .box
          b-field(:label="$t('customer.notes_label')")
            b-input(:placeholder="$t('customer.notes_placeholder')" type="textarea" v-model="notes")  
          b-field(:label="$t('customer.recommendations_label')" v-if="company.crm.recommendationsField")
            b-input(:placeholder="$t('customer.recommendations_placeholder')" type="textarea" v-model="recommendations")  
  footer.modal-card-foot
    .buttons
      action-button(title="actions.save" :loading="submitting" @click="submit(false)" permission="CLIENTS_EDIT" data-qa="action-save")
      action-button.is-hidden-mobile(v-if="!customerId && !oneOnly" title="actions.save_and_add" :loading="submitting" @click="submit(true)" permission="CLIENTS_EDIT" data-qa="action-save-and-new")
      button.button.is-outlined(type="button" @click="cancel" v-t="'actions.cancel'" data-qa="action-cancel")
</template>

<script>
import {mapState, mapActions} from 'vuex'
import dayjs from 'dayjs'
import ProfilePic from './ProfilePic'
import {PRODUCTS} from '../../../constants/products'
import DEPOSIT_GROUP from '../../../constants/depositGroup'
import GENDERS from '../../../constants/genders'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CustomerCreateEditForm',
  components: {
    ProfilePic
  },
  props: {
    customerId: {type: String, default: undefined},
    oneOnly: {type: Boolean, default: false},
    initialPhone: {type: String, default: undefined},
    type: {type: String, default: undefined}
  },
  data() {
    return {
      PRODUCTS,
      DEPOSIT_GROUP,
      firstName: null,
      middleName: null,
      lastName: null,
      cardId: null,
      internalName: null,
      birthday: null,

      phone: null,
      altPhone: null,
      phoneExists: false,
      phoneValid: undefined,
      phoneAltValid: undefined,

      email: null,
      tags: [],
      acceptEmail: true,
      acceptSMS: true,
      acceptMessage: true,
      gender: null,
      genders: Object.values(GENDERS),
      lng: null,
      instagram: null,
      facebook: null,
      notes: null,
      recommendations: null,
      affiliate: false,
      sendSmsInvite: false,
      depositGroup: undefined,
      address: {
        street: undefined,
        streetAlt: undefined,
        city: undefined,
        zip: undefined,
        state: undefined
      },

      submitting: false,
      loading: false
    }
  },
  computed: {
    ...mapState('company', ['company']),
    title() {
      if (this.affiliate) {
        return this.customerId ? 'customer.edit_affiliate_title' : 'customer.new_affiliate_title'
      }
      return this.customerId ? 'customer.edit_client_title' : 'customer.new_client_title'
    },
    phoneErrorMessage() {
      if (this.errors.has('phone')) {
        return this.errors.first('phone')
      }
      if (this.phoneValid === false) {
        return this.$t('customer.phone_invalid_message')
      }

      if (this.phoneExists) {
        return this.$t('customer.phone_exists_message')
      }
      return undefined
    },
    altPhoneErrorMessage() {
      if (this.errors.has('altPhone')) {
        return this.errors.first('altPhone')
      }
      if (this.altPhoneValid === false) {
        return this.$t('customer.phone_invalid_message')
      }

      return undefined
    }
  },
  async created() {
    this.loading = true
    try {
      if (this.customerId) {
        this.customer = await this.freshFetchCustomer(this.customerId)
        this.firstName = this.customer.firstName
        this.middleName = this.customer.middleName
        this.lastName = this.customer.lastName
        this.cardId = this.customer.cardId
        this.internalName = this.customer.internalName
        this.birthday = this.customer.birthday && dayjs(this.customer.birthday.toString(), 'YYYYMMDD').toDate()
        this.phone = this.customer.phone
        this.altPhone = this.customer.altPhone
        this.gender = this.customer.gender
        this.email = this.customer.email
        this.tags = this.customer.tags || []
        this.affiliate = this.customer.affiliate
        this.acceptSMS = this.customer.acceptSMS
        this.acceptMessage = this.customer.acceptMessage
        this.acceptEmail = this.customer.acceptEmail
        this.lng = this.customer.lng
        this.notes = this.customer.notes
        this.recommendations = this.customer.recommendations
        this.facebook = this.customer.facebook
        this.instagram = this.customer.instagram

        this.address = cloneDeep(this.customer.address) || {}

        this.depositGroup = this.customer.depositGroup || this.company.deposit.defaultRatio
      } else if (this.initialPhone) {
        this.affiliate = this.type === 'affiliate'
        this.phone = this.initialPhone
      } else {
        this.affiliate = this.type === 'affiliate'
      }
      this.depositGroup = this.depositGroup || this.company.deposit.defaultRatio || DEPOSIT_GROUP.NONE
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  mounted() {
    this.$refs.firstName.focus()
  },
  methods: {
    ...mapActions('customers', ['freshFetchCustomer', 'updateCustomer', 'createCustomer']),
    async submit(addAnother) {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.phoneExists || this.phoneValid === false || this.altPhoneValid === false) {
        return
      }
      this.submitting = true
      let data = {
        _id: this.customerId,
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        cardId: this.cardId,
        internalName: this.internalName,
        birthday: this.birthday && parseInt(dayjs(this.birthday).format('YYYYMMDD')),
        sendSmsInvite: this.sendSmsInvite,
        phone: this.phone,
        altPhone: this.altPhone,
        email: this.email,
        tags: this.tags,
        affiliate: this.affiliate,
        acceptSMS: this.acceptSMS,
        acceptMessage: this.acceptMessage,
        acceptEmail: this.acceptEmail,
        gender: this.gender,
        lng: this.lng,
        facebook: this.facebook,
        instagram: this.instagram,
        notes: this.notes,
        recommendations: this.recommendations,
        depositGroup: this.depositGroup,

        address: this.address
      }
      try {
        let customer
        if (this.customerId) {
          customer = await this.updateCustomer(data)
        } else {
          customer = await this.createCustomer(data)
        }

        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.submitting = false
        this.$emit('success', customer)
        if (addAnother) {
          this.$emit('reopen')
        }
        this.$emit('close')
      } catch (err) {
        this.submitting = false
        this.$handleAPIError(err)
      }
    },
    cancel() {
      this.$emit('close')
    },
    onPhoneInput(phone, valid, customer) {
      if (!valid) {
        this.sendSmsInvite = false
      }
      if (phone) {
        this.phoneValid = valid
      } else {
        this.phoneValid = undefined
      }
      this.phoneExists = customer && this.customerId !== customer._id
    },
    onAltPhoneInput(phone, valid) {
      if (phone) {
        this.altPhoneValid = valid
      } else {
        this.altPhoneValid = undefined
      }
    }
  }
}
</script>
