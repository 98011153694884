<template lang="pug">
transition(name="slide-fade")
  .box.py-2.px-4.is-clickable(:class="[statusColorClass, statusTextColorClass]" v-if="minimized" @click="minimize(false)")
    .is-flex.is-justify-content-space-between.is-align-items-center
      div
        p.is-size-5.has-text-weight-semibold(style="text-transform: capitalize") {{ longDateFormatter(start) }}
        p(v-if="customer") 
          customer-name(:value="customer" show-phone)
        p(v-else) {{ $t('misc.walkin_customer') }}
      div
        p.is-size-5.has-text-weight-semibold 
        span.icon
          i.mdi.mdi-arrow-top-left-bottom-right.mdi-24px
  .modal-card(v-else)
    header.modal-card-head.py-5(:class="[statusColorClass, statusTextColorClass]" style="align-items: flex-start")
      //p.modal-card-title(data-qa="modal-header") 
      div.is-flex-grow-1 
        .is-flex.is-align-items-center
          div
            
            localized-datepicker.mb-2(v-model="start" :date-formatter="longDateFormatter" expanded size="is-medium" :mobile-native="false")
              template(v-slot:trigger)
                p.is-size-4.has-text-weight-bold.is-clickable(style="text-transform: capitalize") 
                  span {{ longDateFormatter(start) }}
                  span.icon
                    i.mdi.mdi-chevron-down

        .is-flex.is-align-items-center
          div
            a(:class="statusTextColorClass" @click="editRepeatPattern")  
              RepeatPatternView(:value="repeat")
          div 
            location-select.is-size-6.has-text-weight-semibold(v-model="location" :dropdown="false" :class="statusTextColorClass") 
      div
        div.has-text-right.mb-2 
          a.button.is-small.is-ghost.mr-2(v-if="!isMobile()" @click="minimize(true)" :class="statusTextColorClass")
            span.icon
              i.mdi.mdi-format-vertical-align-bottom.mdi-24px
          a.button.is-small.is-ghost(@click="confirmClose" :class="statusTextColorClass")
            span.icon
              i.mdi.mdi-close.mdi-24px
        div(v-if="appointment")
          AppointmentStatusDropdown.is-small(:appointmentId="appointmentId" :button-color="buttonColor")
    section.modal-card-body.is-flex.is-flex-direction-column.is-clipped.p-0.is-relative(ref="mainContent" style="flex-flow: nowrap")
        em-loading(:active="loading" :is-full-page="false")
        .box.is-radiusless.p-4.is-flex-shrink-0.m-0
          customer-tiny-preview.p-0(:value="customer" v-if="customer" tags  :closable="true" @close="customer = undefined" link )
          .has-text-centered.is-clickable(v-else @click="openCustomerSelectModal")
            button.button.is-outlined.is-primary.is-rounded.is-medium
              span.icon
                i.mdi.mdi-account-circle-outline.mdi-24px
              span.is-size-5 {{ $t('actions.select_customer') }}
          .has-border-top.pt-3.mt-3(v-if="customer")
            .columns.is-mobile
              .column(v-if="$rfHasPermission('CLIENT_SALES_DATA')")
                p.headin.is-size-7(v-t="'customers.sales_heading'")
                p.title.is-6 
                  currency-view(:value="customer.sales")
              .column
                p.headin.is-size-7(v-t="'appointments.visits_heading'")
                p.title.is-6 {{customer.visits}}
                  
              .column
                p.headin.is-size-7(v-t="'appointments.no_shows_heading'")
                p.title.is-6.has-text-danger {{customer.noshows || '-'}}
              //.column
                p.headin.is-size-7(v-t="'appointments.cancelations_heading'")
                p.title.is-6 {{customer.canceled || '-'}}
              .column
                p.headin.is-size-7(v-t="'customer.next_appt_heading'")
                NextPurchase(:customer="customer")
              .column.is-hidden-mobile(v-if="$rfSubscribed('rewards') && $rfEnabled('rewards') && $rfHasPermission('CLIENT_SALES_DATA')")
                p.headin.is-size-7(v-t="'customer.rewards_heading'")
                rewards-view(:value="customer.balance")
            //p.has-text-grey  
              currency-view(:value="customer.sales")
              |  |   
              span visits {{customer.visits}}
              |  |    
              span no-shows {{customer.noshows}}
          .has-border-top.pt-3.mt-3.is-hidden-mobile(v-if="customer  && customer.notes")
            p {{ customer.notes }}
          //.pt-4.is-hidden-mobile
            localized-datepicker(v-model="start" size="is-medium" :date-formatter="longDateFormatter" icon="calendar" expanded :mobile-native="false")
          //.p-4
            .is-flex.is-align-items-center
              AppointmentStatusDropdown(v-model="status")
              ColorPicker.ml-2(v-model="color")
              
        
          //.box.is-radiusless.p-4(style="position: sticky; top: 0; z-index: 10;")
        .is-flex-grow-1(style="overflow:scroll")
          .p-4
            .mb-4(v-for="(service, idx) in services" :key="service._id")
              AppointmentItemEdit(
                v-model="service.item" 
                :rebooking="!!rebookingId" 
                
                @remove="removeItem(idx)" 
                :removable="true" 
                :locationId="location" 
                :date="start")    
              
              .is-flex.is-justify-content-center(v-if="idx !== services.length - 1 && services.length > 1") 
                .p-2.has-text-danger.has-text-centered.brand-bordered(v-if="getEnd(service.item) > services[idx + 1].item.start" ) 
                  span.icon.is-medium.mr-3
                    i.mdi.mdi-format-vertical-align-center.mdi-24px
                  span(style="vertical-align: 2px")
                    span {{ getEnd(service.item) -  services[idx + 1].item.start}}{{$t('misc.min_symbol')}} {{ $t('appointment.overlap') }} 
                    span  |  
                    a.has-text-underline.has-text-weight-semibold(@click="fixOverlap(idx, idx + 1)") {{ $t('actions.fix') }}
                  span.icon.is-medium.ml-3
                    i.mdi.mdi-format-vertical-align-center.mdi-24px
                
                .p-2.has-text-danger.has-text-centered.brand-bordered(v-if="getEnd(service.item) < services[idx + 1].item.start") 
                  span.icon.is-medium.mr-3
                    i.mdi.mdi-arrow-expand-vertical.mdi-24px
                  span(style="vertical-align: 2px") 
                    span {{services[idx + 1].item.start - getEnd(service.item)}}{{$t('misc.min_symbol')}} {{ $t('appointment.gap') }}
                    span  |  
                    a.has-text-underline.has-text-weight-semibold(@click="fixGap(idx, idx + 1)") {{ $t('actions.fix') }}
                  span.icon.is-medium.ml-3
                    i.mdi.mdi-arrow-expand-vertical.mdi-24px
              
              

            AddAppointmentShortcuts.mt-5(:locationId="location" :customerId="customer && customer._id" :staffId="services.length && services[services.length - 1].item.staff || staffId" @input="addService")


            
          .p-4
            a(v-if="(appointment && !appointment.notes) || (!appointment && !notes)" @click="addNotes") 
              b(v-t="'appointment.add_notes'")
              span.icon
                i.mdi.mdi-note-edit-outline
            .notification.p-4.is-clickable.is-hoverable(@click="addNotes" v-else)
              p {{ notes || appointment.notes }}

            //b-field(:label="$t('appointment.notes_title')")
              b-input.is-fullwidth(v-model="notes" type="textarea" :placeholder="$t('appointment.notes_placeholder')")
          //.p-4(v-if="appointment.payment")
            label.label {{ $t('payment_types.deposit') }}
            payment-view(:value="appointment.payment" show-status)
          .p-4.has-background-light(v-if="appointment")
            label.label(v-t="'appointment_history.title'")
            AppointmentStatusHistory(:appointment="appointment")
        
    footer.modal-card-foot
      div.is-flex-grow-1
        div 
          h3.title.is-5.mb-4.is-size-6-mobile {{$t('appointment.total_price_label')}}  
            .is-pulled-right
              duration-view.has-text-weight-normal.has-text-grey(:value="totalDuration")
              |  ·    
              span.has-text-weight-semibold
                currency-view(:value="totalPrice")
                template(v-if="totalPrice !== totalPriceUpto") 
                  |  -  
                  currency-view(:value="totalPriceUpto")
              payment-view.ml-2(v-if="appointment && appointment.payment" :value="appointment.payment" show-status style="vertical-align: -4px")
            
        .buttons.is-right(style="width: 100%")
          div.mr-3
            ColorPicker(v-model="color" position="is-top-right") 
          button.button.is-danger.is-outlined(style="margin-left: auto" @click="abortAppointment" v-if="appointment")
            span.icon
              i.mdi.mdi-dots-horizontal.mdi-24px
          
          action-button.is-outlined(title="appointment.actions.save_and_checkout" @click="onCheckout" :disabled="notCheckoutable" icon="basket" :loading="saving" permission="SALE_CREATE" data-qa="action-checkout")
          action-button(:title="`actions.save`" @click="save" :loading="saving" permission="APPOINTMENT_CREATE" data-qa="action-save")
        
</template>

<script>
import {mapActions, mapState} from 'vuex'
import dayjs from 'dayjs'
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'
import PERMISSIONS from '../../../constants/permissions'
import AppointmentItemEdit from './AppointmentItemEdit'
import AppointmentAbortActions from './AppointmentAbortActions'
import AppointmentStatusHistory from './AppointmentStatusHistory'
//import AppointmentDetails from './AppointmentDetails'
import APPT_STATUS_VISUALS from './appointmentStatusVisuals'
import ColorPicker from './ColorPicker'
import AppointmentStatusDropdown from './AppointmentStatusDropdown'
import Checkout from '../sale/Checkout'
import AddAppointmentShortcuts from './AddAppointmentShortcuts'
import CustomerSelectModalWrapper from '../shared/CustomerSelectModalWrapper'
import NextPurchase from '../customers/NextPurchase'
import RepeatSelectorModal from './RepeatSelectorModal.vue'
import RepeatPatternView from './RepeatPatternView'
import cloneDeep from 'lodash/cloneDeep'
import EditNotes from './EditNotes.vue'
import isEqual from 'lodash/isEqual'

export default {
  name: 'CreateEditAppointment',
  components: {
    AppointmentItemEdit,
    ColorPicker,
    AppointmentStatusHistory,
    AppointmentStatusDropdown,
    AddAppointmentShortcuts,
    NextPurchase,
    RepeatPatternView
  },
  props: {
    customerId: {type: String, default: undefined},
    locationId: {type: String, default: undefined},
    appointmentId: {type: String, default: undefined},
    startDate: {type: Date, default: undefined},
    staffId: {type: String, default: undefined},
    preferred: {type: Boolean, default: false},
    preferredResource: {type: Boolean, default: false},
    service: {type: Object, default: undefined},
    initialServices: {type: Array, default: undefined},
    resourceId: {type: String, default: undefined},
    rebookingId: {type: String, default: undefined},
    forceFetch: {type: Boolean, default: false}
  },
  data() {
    return {
      itemId: 0,
      customer: undefined,
      services: [],
      start: dayjs()
        .startOf('day')
        .toDate(),
      location: undefined,
      saving: false,
      loading: true,
      color: undefined,
      status: APPOINTMENT_STATUS.NEW,
      updatingStatus: false,
      // appointment: undefined,
      notes: undefined,
      repeat: undefined,
      minimized: false
    }
  },

  computed: {
    ...mapState('company', ['company']),
    ...mapState('appointments', ['appointmentsMap']),
    statusColorClass() {
      if (this.appointment) {
        return `has-background-${APPT_STATUS_VISUALS[this.appointment.status].color}`
      }
      return 'has-background-light'
    },
    statusTextColorClass() {
      if (this.appointment) {
        return `has-text-${APPT_STATUS_VISUALS[this.appointment.status].text || 'black'}`
      }
      return 'has-text-black'
    },
    buttonColor() {
      if (this.appointment) {
        return `is-${APPT_STATUS_VISUALS[this.appointment.status].text || 'black'}`
      }
      return 'is-black'
    },
    totalPrice() {
      if (!this.services.length) {
        return 0
      }
      return this.services.reduce((sum, service) => sum + (service.item.price || 0), 0)
    },
    totalPriceUpto() {
      if (!this.services.length) {
        return 0
      }
      return this.services.reduce((sum, service) => sum + (service.item.priceUpto || service.item.price || 0), 0)
    },
    totalDuration() {
      if (!this.services.length) {
        return 0
      }
      return this.services.reduce((sum, service) => sum + (service.item.duration || 0), 0)
    },

    notCheckoutable() {
      return (
        (this.appointment &&
          (this.appointment.status === APPOINTMENT_STATUS.DRAFT ||
            this.appointment.status === APPOINTMENT_STATUS.INCOMPLETE)) ||
        (!this.appointment && !this.$rfHasPermission('APPOINTMENT_CREATE'))
      )
    },
    appointment() {
      if (this.appointmentId) {
        return this.appointmentsMap[this.appointmentId] //this.appointmentById(this.appointmentId)
      }
      return undefined
    },
    hasBeenModified() {
      if (this.appointment && this.services.length) {
        let newStart = dayjs(this.start)
          .add(
            this.services.reduce((min, service) => Math.min(min, service.item.start), this.services[0].item.start),
            'minutes'
          )
          .toDate()

        return (
          new Date(this.appointment.start).valueOf() !== newStart.valueOf() ||
          this.appointment.customer !== (this.customer && this.customer._id) ||
          this.appointment.color !== this.color ||
          this.appointment.location !== this.location ||
          !isEqual(this.repeat, this.appointment.repeat) ||
          this.appointment.services.length !== this.services.length ||
          this.appointment.services.some((s, idx) => {
            let item = this.services[idx].item

            return (
              s.service !== item.service.serviceId ||
              s.option !== item.service.optionId ||
              s.price !== item.price ||
              s.priceUpto !== item.priceUpto ||
              s.duration !== item.duration ||
              s.resource !== item.resource ||
              s.blockedTimeAfter !== item.blockedTimeAfter ||
              s.processingTimeAfter !== item.processingTimeAfter ||
              s.staff !== item.staff ||
              s.preferred !== item.preferred ||
              s.preferredResource !== item.preferredResource ||
              s.credit !== item.credit ||
              this.getTimeOffset(s.start) !== item.start
            )
          })
        )
      }
      return true
    }
  },
  watch: {
    start() {
      this.$emit('startChange', this.start)
    },
    location() {
      this.$emit('locationChange', this.location)
    }
    // appointment() {
    //   for (let service of this.appointment.services) {
    //     let itemService = this.services.find(s => s.item._id === service._id)
    //     if (!itemService) {
    //       continue
    //     }
    //     let item = itemService.item
    //     item.duration = service.duration
    //     let start = service.start && new Date(service.start)
    //     if (start) {
    //       start = start.getHours() * 60 + start.getMinutes()
    //     }
    //     item.start = start
    //     item.staff = service.staff
    //     item.resource = service.resource
    //   }
    // }
  },

  async created() {
    if (this.customerId) {
      this.customer = await this.fetchCustomer(this.customerId)
    }
    if (this.appointmentId) {
      await this._fetchAppointment()
    } else if (this.rebookingId) {
      await this._fetchPreviousAppointment()
    } else {
      if (this.initialServices) {
        this.initInitialServices()
      }
      if (this.locationId) {
        this.location = this.locationId
      }
      if (this.startDate) {
        this.start = dayjs(this.startDate)
          .startOf('day')
          .toDate()
      }
    }

    this.loading = false
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    ...mapActions('appointments', ['createAppointment', 'updateAppointment', 'fetchAppointmentById']),

    minimize(toggle) {
      this.minimized = toggle
      if (toggle) {
        document.getElementsByClassName('half-modal')[0].classList.add('is-minimized')
      } else {
        document.getElementsByClassName('half-modal')[0].classList.remove('is-minimized')
      }
    },

    initInitialServices() {
      // let start = this.startDate || new Date()
      // if (start.getHours() === 0 && start.getMinutes() === 0) {
      //   start = new Date()
      // }
      // start.setMinutes(parseInt(start.getMinutes() / 5) * 5, 0, 0)
      // start = start.getHours() * 60 + start.getMinutes()

      this.services = this.initialServices.map((s, idx) => {
        this.itemId = this.itemId + idx

        return {
          item: {
            start: this.getTimeOffset(s.start),
            service: {serviceId: s.service, optionId: s.option},
            duration: s.duration,
            price: s.price,
            priceUpto: s.priceUpto,
            blockedTimeAfter: s.blockedTimeAfter,
            processingTimeAfter: s.processingTimeAfter,
            qty: 1,
            staff: s.staff,
            preferred: s.preferred,
            preferredResource: s.preferredResource,
            resource: s.resource
          },
          _id: this.itemId
        }
      })
    },
    getTimeOffset(date) {
      date = new Date(date)
      return date.getHours() * 60 + date.getMinutes()
    },
    async _fetchAppointment() {
      await this.fetchAppointmentById({id: this.appointmentId, force: this.forceFetch})
      if (!this.customer && this.appointment.customer) {
        this.customer = await this.fetchCustomer(this.appointment.customer)
      }
      this.services = this.appointment.services.map(s => {
        return {
          item: {
            _id: s._id,
            service: {serviceId: s.service, optionId: s.option},
            qty: s.qty || 1,
            price: s.price,
            priceUpto: s.priceUpto,
            duration: s.duration,
            resource: s.resource,
            blockedTimeAfter: s.blockedTimeAfter,
            processingTimeAfter: s.processingTimeAfter,
            staff: (s.staff && s.staff._id) || s.staff,
            preferred: s.preferred,
            preferredResource: s.preferredResource,
            credit: s.credit,
            start: this.getTimeOffset(s.start),
            invalid: undefined
          },
          _id: s._id
        }
      })
      this.start = dayjs(this.appointment.start)
        .startOf('day')
        .toDate()
      this.location = this.appointment.location

      this.repeat = cloneDeep(this.appointment.repeat)
      // // WORKAROUND - temporary fix delete later
      // if (this.status === APPOINTMENT_STATUS.COMPLETED) {
      //   this.status = APPOINTMENT_STATUS.NEW
      // }
      this.color = this.appointment.color
    },
    async _fetchPreviousAppointment() {
      const rebookedAppt = await this.fetchAppointmentById({id: this.rebookingId})
      if (!this.customer && rebookedAppt.customer) {
        this.customer = await this.fetchCustomer(rebookedAppt.customer)
      }

      const isWithinRebookingWindow =
        this.$rfCRMSettings().rebooking && dayjs().diff(rebookedAppt.start, 'hours') <= this.company.crm.rebookingWindow
      this.services = rebookedAppt.services.map((s, idx) => {
        return {
          item: {
            service: {serviceId: s.service, optionId: s.option},
            qty: s.qty || 1,
            price: s.price,
            priceUpto: s.priceUpto,
            duration: s.duration,
            resource: s.resource,
            blockedTimeAfter: s.blockedTimeAfter,
            processingTimeAfter: s.processingTimeAfter,
            staff: (s.staff && s.staff._id) || s.staff,
            preferred: s.preferred,
            preferredResource: s.preferredResource,
            credit: (isWithinRebookingWindow && ((s.staff && s.staff._id) || s.staff)) || undefined,
            start: this.getTimeOffset(s.start),
            invalid: undefined
          },
          _id: idx
        }
      })
      this.itemId = this.services.length
      let daysDiff
      if (this.customer && this.customer.nextVisitDue) {
        daysDiff = dayjs(this.customer.nextVisitDue).diff(rebookedAppt.start, 'day')
      } else {
        daysDiff = 14
      }
      this.start = dayjs(rebookedAppt.start)
        .add(daysDiff, 'days')
        .startOf('day')
        .toDate()
      this.location = rebookedAppt.location
    },
    setTime(date, time) {
      return dayjs(date)
        .startOf('day')
        .add(time, 'minutes')
        .toDate()
    },
    async onCheckout() {
      if (!this.appointment && !this.$rfHasPermission(PERMISSIONS.core.APPOINTMENT_CREATE)) {
        return
      }
      if (this.appointment && !this.$rfHasPermission(PERMISSIONS.core.APPOINTMENT_CREATE)) {
        this.$emit('close')
        this.checkout(this.appointment._id)
        return
      }
      let appt = await this.save(true)
      if (appt) {
        this.checkout(appt._id)
      }
    },
    async save(checkout) {
      try {
        let data = {
          customer: this.customer && this.customer._id,
          services: this.services
            //.filter(s => s.item.service)
            .map(service => {
              const item = service.item
              let start = item.start && this.setTime(this.start, item.start)

              return {
                //_id: item._id,
                service: item.service && item.service.serviceId,
                option: item.service && item.service.optionId,
                qty: item.qty,
                price: item.price,
                priceUpto: item.priceUpto,
                duration: item.duration,
                resource: item.resource,
                blockedTimeAfter: item.blockedTimeAfter,
                processingTimeAfter: item.processingTimeAfter,
                staff: item.staff,
                preferred: item.preferred,
                preferredResource: item.preferredResource,
                credit: item.credit,
                start
              }
            }),
          color: this.color,
          location: this.location,
          notes: (!this.appointmentId && this.notes) || undefined,
          repeat: this.repeat,
          previous: this.rebookingId
        }
        if (!data.services.length) {
          this.$buefy.toast.open({
            message: this.$t('appointment.no_appointment_items'),
            type: 'is-warning',
            queue: false
          })
          return
        }

        data.services.sort((a, b) => a.start - b.start)

        let firstService = data.services.find(s => s.service)
        data.start = (firstService && firstService.start) || this.start

        this.saving = true
        let appt
        if (this.appointment) {
          if (this.appointment.repeat) {
            if (checkout) {
              data.thisOnly = true
            } else {
              const {result} = await this.$buefy.dialog.confirm({
                title: this.$t('appointment.repeat_appt_change_confirm.title'),

                message: this.$t('appointment.repeat_appt_change_confirm.body'),
                cancelText: this.$t('actions.all_future'),
                confirmText: this.$t('actions.only_this'),
                type: 'is-black',
                canCancel: ['button']
              })
              data.thisOnly = result
            }
          }
          appt = await this.updateAppointment({appointmentId: this.appointment._id, data})
        } else {
          appt = await this.createAppointment(data)
        }

        this.$buefy.toast.open({message: this.$t('appointment.save_success'), type: 'is-success', queue: false})

        this.$emit('close')
        return appt
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.saving = false
    },

    checkout(appointmentId) {
      this.$buefy.modal.open({
        parent: this.$parent,
        props: {
          appointmentId
        },
        component: Checkout,
        hasModalCard: true,
        fullScreen: true,
        canCancel: ['outside']
      })
    },
    getEnd(item) {
      return item.start + item.duration + (item.blockedTimeAfter || item.processingTimeAfter || 0)
    },
    fixOverlap(idx) {
      let diff = this.getEnd(this.services[idx].item) - this.services[idx + 1].item.start
      for (let i = idx + 1; i < this.services.length; i++) {
        this.services[i].item.start = this.services[i].item.start + diff
        this.services[i].item = {...this.services[i].item}
      }
    },
    fixGap(idx) {
      let diff = this.services[idx + 1].item.start - this.getEnd(this.services[idx].item)
      for (let i = idx + 1; i < this.services.length; i++) {
        this.services[i].item.start = this.services[i].item.start - diff
        this.services[i].item = {...this.services[i].item}
      }
    },
    onTerminate() {
      this.$emit('close')
    },
    longDateFormatter(date) {
      return dayjs(date).format(this.isMobile() ? 'ddd, D MMMM' : 'ddd, D MMMM')
    },
    removeItem(idx) {
      this.services.splice(idx, 1)
    },
    async addService({serviceId, optionId}) {
      let start, staff, resource, preferred, preferredResource
      if (this.services.length) {
        const lastServiceItem = this.services[this.services.length - 1].item
        if (this.$rfCRMSettings().disableTimeAutoAdjust) {
          start = lastServiceItem.start
        } else {
          start =
            lastServiceItem.start +
            lastServiceItem.duration +
            (lastServiceItem.blockedTimeAfter || lastServiceItem.processingTimeAfter || 0)
        }

        staff = lastServiceItem.staff
        preferredResource = lastServiceItem.preferredResource
      } else {
        start = this.startDate || new Date()
        if (start.getHours() === 0 && start.getMinutes() === 0) {
          start = new Date()
        }
        start.setMinutes(parseInt(start.getMinutes() / 5) * 5, 0, 0)
        start = start.getHours() * 60 + start.getMinutes()
        staff = this.staffId
        resource = this.resourceId
        preferred = this.preferred
        preferredResource = this.preferredResource
      }

      this.itemId += 1
      this.services.push({
        item: {
          start,
          service: {serviceId, optionId},
          invalid: undefined,
          staff,
          resource,
          duration: undefined,
          qty: 1,
          preferred,
          preferredResource
        },
        _id: this.itemId
      })
      this.scrollDown()
    },
    async scrollDown() {
      await this.$nextTick()
      let el = this.$refs.mainContent
      el.scrollTop = el.scrollHeight - 660
    },
    openCustomerSelectModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        component: CustomerSelectModalWrapper,
        events: {
          input: customer => {
            this.customer = customer
          }
        },
        canCancel: ['escape']
      })
    },
    abortAppointment() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        component: AppointmentAbortActions,
        props: {
          appointmentId: this.appointment._id,
          paymentId: this.appointment.payment
        },
        events: {
          terminated: this.onTerminate
        },
        canCancel: ['escape']
      })
    },
    editRepeatPattern() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        component: RepeatSelectorModal,
        props: {
          value: this.repeat || {},
          currentDate: this.start
        },
        events: {
          input: pattern => {
            this.repeat = pattern
          }
        },
        canCancel: ['escape']
      })
    },
    setStart(date) {
      if (date.valueOf() !== this.start.valueOf()) {
        this.start = date
      }
    },
    setLocation(locationId) {
      if (this.location !== locationId) {
        this.location = locationId
      }
    },
    confirmClose() {
      if (!this.hasBeenModified) {
        this.$emit('close')
        return
      }

      this.$buefy.dialog.confirm({
        title: this.$t('unsaved_confirm.title'),
        message: this.$t('unsaved_confirm.body'),
        cancelText: this.$t('actions.go_back'),
        confirmText: this.$t('actions.leave'),
        type: 'is-black',
        onConfirm: () => {
          this.$emit('close')
        }
      })
    },
    addNotes() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        component: EditNotes,
        props: {
          appointmentId: this.appointmentId,
          value: this.notes
        },
        events: {
          input: notes => {
            this.notes = notes
          }
        },
        canCancel: ['escape']
      })
    }
  }
}
</script>
