<template lang="pug">
span(v-if="customer" ) 
  customer-name.has-text-weight-semibold(:value="customer")
  |    
  phone-view.has-text-black(:value="customer.phone" :tag="false" :icon="false" :customer="customer")
  template(v-if="customer.altPhone")
    | &nbsp;
    phone-view.has-text-black(:value="customer.altPhone" :tag="false" :icon="false" :customer="customer")  
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'CustomerContactView',
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      customer: undefined,
      loadFailed: false
    }
  },
  async created() {
    try {
      this.customer = await this.fetchCustomer(this.customerId)
    } catch (err) {
      this.loadFailed = true
    }
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer'])
  }
}
</script>
