module.exports = {
  lng: 'lng',
  gender: 'gender',
  tags: 'tags',

  birthdate: 'birthdate',

  lastVisit: 'lastVisit',
  nextVisit: 'nextVisit',
  nextVisitDue: 'nextVisitDue',
  visits: 'visits',
  canceled: 'canceled',
  noshows: 'noshows',

  sales: 'sales',
  'stats.tbp': 'stats.tbp',
  'stats.avgSale': 'stats.avgSale',

  phone: 'phone',
  email: 'email',

  favServices: 'favServices',
  favStaff: 'favStaff',

  balance: 'balance',
  membership: 'membership',

  avgRaring: 'surveys.feedback.value',
  nps: 'surveys.nps.value'
}
