<template lang="pug">
  .media.flex-center(v-if="customer" @click="clickable && showDetails()" :class="{'has-pointer-cursor': clickable}" style="position:relative")
    .media-left
      ProfilePic(:customer="customer" :class="detailed ? 'is-48x48' : 'is-24x24'")
    .media-content.has-text-left
      strong 
        customer-name(:value="customer")
      |  
      small(v-if="customer.internalName") ({{customer.internalName}})
      |  
      span.icon.is-small.has-text-info(:title="$t('customer.messenger_profile_tooltip')" v-if="customer.sender")
          i.mdi.mdi-facebook-messenger
      | 
      span.icon.is-small(:title="customer.cardId" v-if="customer.cardId")
          i.mdi.mdi-wallet-membership
      template(v-if="detailed")
        br
        MembershipTag(:customer="customer")
        |  
        BirthdayTag(:customer="customer")
        |  
        phone-view(:value="customer.phone" :customer="customer")
        template(v-if="customer.altPhone")
          |   
          phone-view(:value="customer.altPhone" :customer="customer")
    .media-right(v-if="deletable" )
      button.delete.has-text-danger(@click="onDelete")
  .flex-center(v-else-if="loadFailed")
    span.icon.is-medium.has-text-grey
      i.mdi.mdi-close-octagon-outline.mdi-24px
    span.has-text-grey(v-t="'customer.removed_warning'")
</template>

<script>
import MembershipTag from './MembershipTag'
import BirthdayTag from './BirthdayTag'
import ProfilePic from './ProfilePic'
import {mapActions} from 'vuex'
import Details from './Details'
import CustomerDetailsMobile from './CustomerDetailsMobile.vue'

export default {
  name: 'CustomerSimplePreview',
  components: {
    ProfilePic,
    MembershipTag,
    BirthdayTag
  },
  props: {
    customerId: {type: String, required: true},
    detailed: {type: Boolean, default: true},
    deletable: {type: Boolean, default: false},
    clickable: {type: Boolean, default: false}
  },
  data() {
    return {
      customer: undefined,
      loadFailed: false
    }
  },
  async created() {
    try {
      this.customer = await this.fetchCustomer(this.customerId)
    } catch (err) {
      this.loadFailed = true
    }
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    onDelete() {
      this.$emit('delete')
    },
    showDetails() {
      this.$buefy.modal.open({
        parent: this,
        component: this.isMobile() ? CustomerDetailsMobile : Details,
        hasModalCard: true,
        fullScreen: true,
        props: {customerId: this.customer._id},
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
