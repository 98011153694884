<template lang="pug">
a.is-rounded.is-no-break(@click.stop="onPhoneClick" v-if="value" :title="tooltip" :class="cssClasses" style="white-space: nowrap") 
  span.icon.is-small(v-if="icon")
    i.mdi.mdi-phone
  span {{national}} 
</template>
<script>
import PERMISSIONS from '../../../constants/permissions'
import PhoneNumber from 'awesome-phonenumber'
import PhoneActionModal from './PhoneActionModal.vue'
export default {
  name: 'PhoneView',
  props: {
    value: {
      type: String,
      default: undefined
    },
    size: {
      type: String,
      default: undefined
    },
    tag: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'national'
    },
    customer: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      PERMISSIONS
    }
  },
  computed: {
    cssClasses() {
      let res = []
      if (this.tag) {
        res.push('tag')
      }
      if (this.size) {
        res.push(this.size)
      }
      return res
    },
    tooltip() {
      if (!this.$rfHasPermission(PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS)) {
        return undefined
      }
      return this.value
    },
    national() {
      if (!this.value) {
        return undefined
      }
      if (!this.$rfHasPermission(PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS)) {
        return '********'
      }
      let pn = new PhoneNumber(this.value)
      return pn.getNumber(this.type)
    }
  },
  methods: {
    onPhoneClick() {
      if (!this.$rfHasPermission(PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS)) {
        return
      }
      this.$buefy.modal.open({
        component: PhoneActionModal,
        parent: this,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        props: {
          phone: this.value,
          customer: this.customer
        },
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
