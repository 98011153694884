<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'customer.noname_title'") 
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px

  section.modal-card-body.has-background-light.p-0.is-flex.is-flex-align-items-stretch.is-flex-direction-column(v-if="customer")
    .is-flex-shrink-0.p-0.has-background-white.has-border-bottom
      .is-flex.p-4
        .mr-3.is-flex-shrink-0
          ProfilePic.is-64x64(:customer="customer" style="margin: 0 auto")
        .mr-3.is-flex-grow-1
          p.title.is-5 
            customer-name(:value="customer")
            span(v-if="customer.lng")  
              span(v-if="customer.lng === 'en_US'")  🇬🇧
              span(v-else-if="customer.lng === 'hy_AM'")  🇦🇲
              span(v-else-if="customer.lng === 'ru_RU'")  🇷🇺
          p.subtitle.is-6.mb-2
            template(v-if="customer.phone") 
              phone-view(:value="customer.phone" :customer="customer")
          CustomerTagsPreview.mb-0(:customer="customer" v-if="$rfSubscribed(PRODUCTS.CRM_PRO)")
          p
            CustomerLoyalty.mr-3(:customer="customer" v-if="$rfSubscribed(PRODUCTS.CHURN_PREDICTION)")
            DepositGroupView(:customer="customer" v-if="$rfSubscribed(PRODUCTS.PAYMENTS)")
      .tabs.mb-0
        ul
          li(:class="view === 'overview' ? 'is-active': ''")
            a.is-light(@click="view = 'overview'" :class="view === 'overview'?'ff': ''") 
              span.icon
                i.mdi.mdi-list-box-outline
              span.has-text-weight-semibold {{$t('customer.overview_heading')}}
          li(:class="view === 'details' ? 'is-active': ''")
            a.is-light(@click="view = 'details'" :class="view === 'details'?'ff': ''") 
              span.icon
                i.mdi.mdi-account-box-outline
              span.has-text-weight-semibold {{$t('customer.contact_details_heading')}}
          li(v-if="$rfSettings().calendar && !company.rewardsOnly" :class="view === 'appointments' ? 'is-active': ''")
            a.is-light(@click="view = 'appointments'" :class="view === 'appointments'?'ff': ''") 
              span.icon
                i.mdi.mdi-calendar
              span.has-text-weight-semibold {{$t('customer.appointments_heading')}}
          li(:class="view === 'sales' ? 'is-active': ''")
            a.is-light(@click="view = 'sales'" :class="view === 'sales'?'ff': ''")
              span.icon
                i.mdi.mdi-basket-outline
              span.has-text-weight-semibold {{$t('customer.sales_heading')}}
          li(v-if="$rfSubscribed('payments')" :class="view === 'payments' ? 'is-active': ''")
            a.is-light(@click="view = 'payments'" :class="view === 'payments'?'ff': ''")  
              span.icon
                i.mdi.mdi-credit-card-outline
              span.has-text-weight-semibold {{$t('customer.payments_heading')}}
              
          li(:class="view === 'notes' ? 'is-active': ''" v-if="$rfSubscribed(PRODUCTS.CRM_PRO)")
            a.is-light(@click="view = 'notes'" :class="view === 'notes'?'ff': ''")
              span.icon
                i.mdi.mdi-file-document-plus-outline
              span.has-text-weight-semibold {{$t('customer.notes_header')}}
          li(:class="view === 'sms' ? 'is-active': ''" )
            a.is-light(@click="view = 'sms'" :class="view === 'sms'?'ff': ''")
              span.icon
                i.mdi.mdi-send
              span.has-text-weight-semibold {{$t('customer.messages_heading')}}

    
          
    .is-flex-grow-1.p-4(v-if="view === 'overview'" style="overflow:scroll")
      .box
        CustomerStatsOverview(:customer="customer")
      
      h3.title.is-5.mb-2
        span(v-t="'customer.stats.appointments_header'")
      .box.p-0.is-clipped 
        CustomerUpcomingAppointments(:customerId="customer._id")
        .p-4.has-border-top
          .columns.is-mobile
            .column
              h3.title.is-7
                span.mr-2(v-t="'customer.est_appt_heading'") 
                EstNextPurchase(:customer="customer")
            .column
              h3.title.is-7
                span.mr-2(v-t="'customer.last_visit_heading'") 
                span.tag.is-warning
                  date-view(v-if="customer.lastVisit" :value="customer.lastVisit" format='D MMM')  
                  span(v-else) N/A
      template(v-if="$rfSubscribed(PRODUCTS.FORMS)")
        h3.title.is-5.mb-2
          span(v-t="'customer.forms_heading'")
        .box 
          CustomerForms(:customerId="customer._id")
      template(v-if="$rfSubscribed(PRODUCTS.REWARDS_PROGRAM)")
        h3.title.is-5.mb-2
          span(v-t="'customer.stats.rewards_program_header'")
        .box.p-0
          CustomerRewardsStats(:customer="customer")    
      
      template(v-if="$rfSubscribed(PRODUCTS.SURVEYS)")
        h3.title.is-5.mb-2
          span(v-t="'customer.stats.surveys_header'")
        .box
          CustomerSurveyStats(:customer="customer")
      Stats(:customer="customer" :blocks="['fav_services','fav_products','fav_staff','fav_locations']")
      
    .is-flex-grow-1.p-0.has-background-white(style="overflow:scroll" v-if="view === 'appointments'")
      CustomerAppointments(:customerId="customer._id" )
    .is-flex-grow-1.p-0.has-background-white(style="overflow:scroll" v-if="view === 'sales'")
      CustomerSales(:customerId="customer._id")
    .is-flex-grow-1.p-4.has-background-white(style="overflow:scroll" v-if="view === 'details'")
      CustomerGeneralInfo(:customer="customer")
    .is-flex-grow-1.p-0.has-background-white(style="overflow:scroll" v-if="view === 'payments'")
      CustomerPayments(:customerId="customer._id")
    .is-flex-grow-1.p-4.has-background-white(style="overflow:scroll" v-if="view === 'notes'")
      NotesList(:entityId="customer._id" entity="customer")
    CustomerMessages.is-flex-grow-1.p-0(:customer="customer" v-if="view === 'sms'")
  footer.modal-card-foot
    .buttons
      action-button(v-if="!company.rewardsOnly && $rfSettings().calendar" @click="newAppointment" icon="calendar" title="appointment.actions.new" permission="APPOINTMENT_CREATE" :bold="false") 
      RepeatLastAppointmentButton.is-primary.is-outlined(:customer="customer" @click="onRebook" v-if="customer && customer.visits && $rfSettings().calendar && !company.rewardsOnly" )
        //span(v-t="'appointment.actions.repeat_last_appt'")
      action-button(v-if="company.rewardsOnly" @click="createSale" title="sale.actions.new_checkout" icon="basket" permission="SALE_CREATE")
      action-button.is-outlined.is-success(v-else @click="createSale" icon="basket" permission="SALE_CREATE")  
      
      
      
          
          
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {PRODUCTS} from '../../../constants/products'

import Stats from './Stats'

import CustomerSales from '../sale/CustomerSales'
import CustomerPayments from '../sale/CustomerPayments'
import CustomerAppointments from '../calendar/CustomerAppointments'
import ProfilePic from './ProfilePic'

import CustomerGeneralInfo from './CustomerGeneralInfo'
import CustomerTagsPreview from './CustomerTagsPreview'
import DepositGroupView from './DepositGroupView'
import CustomerLoyalty from './CustomerLoyalty'

import RepeatLastAppointmentButton from '../calendar/RepeatLastAppointmentButton'

import CustomerMessages from './CustomerMessages'
import CustomerStatsOverview from '../sale/CustomerStatsOverview'
import CustomerUpcomingAppointments from '../calendar/CustomerUpcomingAppointments'
import EstNextPurchase from './EstNextPurchase'
import CustomerForms from '../forms/CustomerForms'
import CustomerSurveyStats from '../surveys/CustomerSurveyStats'
import CustomerRewardsStats from '../rewards/CustomerRewardsStats'
// import CreateEditAppointment from '../calendar/CreateEditAppointment'
import NotesList from '../shared/NotesList'
import Checkout from '../sale/Checkout.vue'
import CheckoutPopup from '../checkout/CheckoutPopup.vue'

export default {
  name: 'CustomerDetailsMobile',
  components: {
    Stats,
    CustomerSales,
    CustomerAppointments,
    ProfilePic,
    CustomerTagsPreview,
    CustomerLoyalty,
    CustomerGeneralInfo,
    RepeatLastAppointmentButton,
    DepositGroupView,
    CustomerMessages,
    CustomerPayments,
    CustomerStatsOverview,
    CustomerUpcomingAppointments,
    EstNextPurchase,
    CustomerForms,
    CustomerRewardsStats,
    CustomerSurveyStats,
    NotesList
  },
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      view: 'overview',
      PRODUCTS,
      loading: false
    }
  },
  computed: {
    ...mapGetters('customers', ['customerById']),
    ...mapState('company', ['company']),
    customer: {
      get() {
        return this.customerById(this.customerId)
      }
    }
  },
  // watch: {
  //   $route() {
  //     this.$emit('close')
  //   }
  // },
  async created() {
    this.loading = true

    try {
      await this.fetchCustomer(this.customerId)
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    createSale() {
      if (this.company.rewardsOnly) {
        this.$buefy.modal.open({
          parent: this,
          component: CheckoutPopup,
          props: {customerId: this.customer._id},
          fullScreen: true,
          canCancel: ['escape']
        })
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Checkout,
          props: {customerId: this.customer._id},
          fullScreen: true,
          hasModalCard: true,
          canCancel: ['escape']
        })
      }
    },
    onRebook({customerId, rebookingId}) {
      this.$emit('close')
      this.$router.push({name: 'Calendar', query: {customer: customerId, rebooking: rebookingId}})
      // this.$buefy.modal.open({
      //   parent: this,
      //   component: CreateEditAppointment,
      //   customClass: !this.isMobile() ? 'half-modal' : '',
      //   animation: !this.isMobile() ? 'slide-right' : 'zoom-out',
      //   hasModalCard: this.isMobile(),
      //   props: {customerId, rebookingId},
      //   fullScreen: true,
      //   canCancel: ['escape']
      // })
    },
    newAppointment() {
      this.$emit('close')
      this.$router.push({name: 'Calendar', query: {customer: this.customer._id}})
      // this.$buefy.modal.open({
      //   parent: this,
      //   component: CreateEditAppointment,
      //   customClass: !this.isMobile() ? 'half-modal' : '',
      //   animation: !this.isMobile() ? 'slide-right' : 'zoom-out',
      //   hasModalCard: this.isMobile(),
      //   props: {customerId: this.customer._id},
      //   fullScreen: true,
      //   canCancel: ['escape']
      // })
    }
  }
}
</script>
