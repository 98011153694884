<template lang="pug">
.section(data-qa="customers-list")
    .columns
      .column
        form(v-on:submit.prevent="search")
          b-field(grouped group-multiline)
            //b-field(v-if="customerCategories && customerCategories.length")
              b-dropdown(v-model="currentCategory" aria-role="list" @input="onFilter")
                template(#trigger)
                  b-button(:label="(isMobile() ? '' : $t('customers.filter') +': ') + $t(`customers.categories.${currentCategory}.title`)" type="is-primary" :icon-left="customerCategories.find(c => c.value === currentCategory).icon" icon-right="menu-down")
                b-dropdown-item(v-for="cat in customerCategories" :key="cat.value" :value="cat.value" aria-role="listitem" :disabled="cat.product && !$rfSubscribed(cat.product)")
                  .media
                    b-icon.media-left(:icon="cat.icon")
                    .media-content
                      p {{$t(`customers.categories.${cat.value}.title`)}}
                      small {{$t(`customers.categories.${cat.value}.desc`)}}
            b-field(expanded)
              b-input(expanded rounded type="text" v-model="queryString" :placeholder="$t('customers.search_placeholder')" autofocus data-qa="search-customer" )
              p.control
                button.button.is-rounded(type="submit", :class="searching ? 'is-loading': ''" data-qa="action-search-customer") 
                  span.icon
                    i.mdi.mdi-magnify
                  //span(v-if="!isMobile()" v-t="'customers.actions.search'")
            
            b-field
              action-button(title="customers.actions.add_new" mobile-icon-only icon="account-plus" @click="openCustomerCreateEditModal" permission="CLIENTS_ADD" data-qa="action-add-new-client" )
            b-field
              a.button(:href="exportLink" target="_blank" v-if="$rfSubscribed(PRODUCTS.CRM_PRO) && $rfHasPermission('CLIENTS_EXPORT') && !isMobile()") 
                span.icon
                  i.mdi.mdi-download
                span {{$t('actions.export_data')}}
    .columns
      .column
        b-field(grouped)
          b-field
            button.button.is-rounded(@click="showFilters" type="button" :disabled="!$rfSubscribed(PRODUCTS.CRM_PRO)" data-qa="action-filter-customers") 
              span.icon
                i.mdi.mdi-filter
              span {{$t('customers.filter')}} 
              b.tag.is-rounded.is-warning.ml-2(v-if="filters.length")  {{filters.length}}
              span.icon(@click.stop="resetFilter" v-if="filters.length")
                i.mdi.mdi-close
          b-field
            b-dropdown(:disabled="!selectedCustomers.length")
              template(#trigger)
                button.button(type="button")
                  span.icon
                    i.mdi.mdi-checkbox-multiple-outline
                  span {{$t('customers.actions.actions')}}
                  b.tag.is-rounded.is-black.ml-2(v-if="selectedCustomers.length")  {{selectedCustomers.length}}
              b-dropdown-item(@click="addTags") {{ $t('customers.actions.edit_tags') }}
              //b-dropdown-item(@click.stop="removeCustomer(prop)" v-if="$rfHasPermission('CLIENTS_REMOVE')" data-qa="action-delete-customer") 
                  span.icon.mdi.mdi-delete-outline 
                  span(v-t="'customers.actions.remove'")
        .mt-4(v-if="mergingCustomer")
          CustomerMerger(:mergingCustomer="mergingCustomer", :mergeWithCustomer="selectedCustomer", @merged="onMergeSuccess", @cancel="onMergeCancel")             
    .columns
      .column
        b-table.is-overflow-visible(
          :data="customers" 
          :loading="searching" 
          hoverable=true 
          @click="selectCustomer" 
          class="is-vertically-aligned" 
          custom-row-key="_id"
          paginated
          checkable
          :checked-rows.sync="selectedCustomers"
          pagination-rounded
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          draggable-column
          :narrowed="isMobile()"
          :mobile-cards="true"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort")
          template(slot="empty")
            empty-content(v-if="!filters.length && !queryString && !searching" title="customers.no_customers")
              action-button(title="customers.actions.add_new" icon="account-plus" @click="openCustomerCreateEditModal" permission="CLIENTS_ADD" data-qa="action-add-new-client")
          
            empty-content(v-else-if="!searching" title="customers.empty_search_result")
          
          template(slot="bottom-left")
            .is-flex.is-align-items-center
              .mr-3
                p(v-html="$t('customers.found_title', {total})") 
              div.mr-3
                p(v-t="'customers.results_per_page'")
              b-field.mb-0
                b-radio-button(v-model="perPage" :native-value="10" @input="onPerPageChange") 10
                b-radio-button(v-model="perPage" :native-value="20" @input="onPerPageChange") 20
                b-radio-button(v-model="perPage" :native-value="40" @input="onPerPageChange") 40
              
                
          b-table-column(field="moreActions" label="" custom-key="moreActions" v-slot="props" :visible="!isMobile()")
            .dropdown(:class="activeDropdownCustomer === props.row ? 'is-active': ''")
              .dropdown-trigger
                span.icon.mdi.mdi-dots-horizontal(@click.stop="toggleDropdown(props.row)" data-qa="action-toggle-more-customer-actions")
              .dropdown-menu(role="menu")
                .dropdown-content.has-text-black
                  a.dropdown-item(@click.stop="startMerge(props.row)" v-if="$rfHasPermission('CLIENTS_MERGE')") 
                    span.icon.mdi.mdi-link-variant 
                    span(v-t="'customers.actions.merge_account'")
                  a.dropdown-item(@click.stop="removeCustomer(props.row)" v-if="$rfHasPermission('CLIENTS_REMOVE')" data-qa="action-delete-customer") 
                    span.icon.mdi.mdi-delete 
                    span(v-t="'customers.actions.remove'")
          
        
          b-table-column(field="cardId" label="#" :sortable="!isMobile()" numeric v-slot="props" :visible="showField('cardId') && !isMobile()")
            strong {{props.row.cardId}}
          b-table-column(field="created" :label="$t('customers.created_heading')" sortable v-slot="props" :visible="showField('created') && !isMobile()")
            date-view(:value="props.row.created" format="LL LT")
          b-table-column(field="firstName" :label="$t('customers.name_heading')" sortable v-slot="props" ) 
            .media(data-qa="name-column")
              .media-left(v-if="!isMobile()")
                ProfilePic.is-48x48(:customer="props.row")
              .media-body
                strong
                  customer-name(:value="props.row")
                //small.has-text-grey(v-else) no name
                span.icon.has-text-primary(v-if="props.row.sender") 
                  i.mdi.mdi-facebook-messenger
                br
                span.tag.is-rounded.is-warning(v-if="props.row.cardId && !showField('cardId')") {{props.row.cardId}}
                  strong # {{props.row.cardId}}
                |   
                phone-view(:value="props.row.phone" :customer="props.row")
                template(v-if="props.row.altPhone")
                  |     
                  phone-view(:value="props.row.altPhone" :customer="props.row")
          b-table-column(field="membership" :label="$t('customers.membership_heading')" sortable v-slot="props" :visible="showField('tags') && ($rfSubscribed(PRODUCTS.CRM_PRO) || $rfSubscribed(PRODUCTS.REWARDS_PROGRAM)) && !isMobile()")
            .tags
              .tag.is-primary(v-if="props.row.affiliate") {{$t('customer.affiliate_option')}}
              MembershipTag(v-if="$rfEnabled(PRODUCTS.REWARDS_PROGRAM) && $rfSubscribed(PRODUCTS.REWARDS_PROGRAM)" :customer="props.row")
              
              .tag.is-info.has-text-weight-semibold(v-if="$rfSubscribed(PRODUCTS.CRM_PRO)" v-for="tag in props.row.tags" :key="tag") {{tag}}

          b-table-column(field="birthdate" :label="$t('customers.birthday_heading')" v-slot="props" :sortable="!isMobile()" :visible="showField('birthdayTag') && !isMobile()")
            BirthdayTag(:customer="props.row")
          b-table-column(field="birthdate" :label="$t('customers.birthday_heading')" v-slot="props" :visible="showField('birthdate') && !isMobile()")
            date-view(v-if="props.row.birthday" :value="props.row.birthday + ''" format="LL")
          b-table-column(field="address" :label="$t('customers.address_heading')" v-slot="props" :visible="showField('address') && !isMobile()")
            AddressView(v-if="props.row.address" :address="props.row.address")
          b-table-column(field="rating" :label="$t('customers.feedback_nps_heading')" :sortable="!isMobile()" centered :visible="showField('surveys') && $rfSubscribed('surveys') && !isMobile()" v-slot="props" )
            avg-feedback-view(:customer="props.row")
            |  
            nps-view(:customer="props.row")
          b-table-column(field="balance" :label="$t('customers.balance_heading')" numeric sortable :visible="showField('balance') && $rfSubscribed(PRODUCTS.REWARDS_PROGRAM) && $rfEnabled(PRODUCTS.REWARDS_PROGRAM) && !isMobile() && $rfHasPermission('CLIENT_SALES_DATA')" v-slot="props")
            strong 
              rewards-view.has-text-success(:value="props.row.balance")
          b-table-column(field="sales" :label="$t('customers.sales_heading')" numeric sortable v-slot="props" :visible="showField('sales') && $rfHasPermission('CLIENT_SALES_DATA')")
            strong 
              currency-view(:value="props.row.sales")
          b-table-column(field="depositGroup" :label="$t('customers.deposit_group_label')" sortable v-slot="props" :visible="showField('depositGroup') && $rfSubscribed(PRODUCTS.PAYMENTS)") 
            DepositGroupView(:customer="props.row")
          //b-table-column(field="sales" :label="$t('customers.sales_heading')" numeric v-slot="props" :visible="showField('sales') && isMobile() && $rfHasPermission('CLIENT_SALES_DATA')")
            p(v-if="$rfSubscribed(PRODUCTS.REWARDS_PROGRAM) && $rfEnabled(PRODUCTS.REWARDS_PROGRAM) && $rfHasPermission('CLIENT_SALES_DATA')")
              strong 
                rewards-view.has-text-success(:value="props.row.balance")
            p
              strong 
                currency-view(:value="props.row.sales")
            p
              strong {{props.row.visits}}
          b-table-column(field="visits" :label="$t('customers.visits_heading')" numeric sortable v-slot="props" :visible="showField('visits')")
            strong {{props.row.visits}}
          b-table-column(field="noshows" :label="$t('customers.noshows_heading')" numeric sortable v-slot="props" :visible="isFilteredBy('noshows')")
            strong {{props.row.noshows}}
          b-table-column(field="canceled" :label="$t('customers.canceled_heading')" numeric sortable v-slot="props" :visible="isFilteredBy('canceled')")
            strong {{props.row.canceled}}
          b-table-column(field="nextVisitDue" :label="$t('customers.est_next_visit')" v-slot="props" :visible="showField('nextVisitDue') && $rfSubscribed(PRODUCTS.CHURN_PREDICTION)")
            .has-text-centered(v-if="props.row.nextVisit && new Date(props.row.nextVisit) > new Date()")
              NextPurchase(:customer="props.row")
              br
              span.is-size-7.has-text-grey(v-t="'customers.next_visit_booking_label'")
            .has-text-centered(v-else-if="props.row.nextVisitDue")
              EstNextPurchase(:customer="props.row")
              br
              span.is-size-7.has-text-grey(v-t="'customers.next_visit_expected_label'")
          b-table-column(field="recommendations" :label="$t('customers.recommendations_heading')" v-slot="props" :visible="showField('recommendations') && !isMobile()")
            p(v-if="props.row.recommendations") {{props.row.recommendations}}
          b-table-column(field="notes" :label="$t('customers.notes_heading')" v-slot="props" :visible="showField('notes') && !isMobile()")
            p(v-if="props.row.notes") {{props.row.notes}}
          
          
          b-table-column(field="updated" :label="$t('customers.activity_heading')" sortable v-slot="props" :visible="showField('activity') && !isMobile()")
            date-view.mr-2(:value="props.row.updated" format="L LT")
            span.tag.is-small.is-success(v-if="isWithin24hWindow(props.row.lastAction)") {{props.row.lastAction | messengerWindow}}
          b-table-column(field="actions" custom-key="actions" v-slot="props" :visible="!isMobile()")
            .field.is-grouped
              .control
                b-tooltip(:label="$t('sale.actions.new_checkout')" type="is-dark")
                  button.button.is-success(@click.stop="checkout(props.row)")
                    span.icon
                      i.mdi.mdi-basket                
              //.control(v-if="$rfSubscribed(PRODUCTS.REWARDS_PROGRAM)")
                button.button(@click.stop="gift(props.row)" :title="$t('customers.send_gift_tooltip')")
                  span.icon.has-text-danger
                    i.mdi.mdi-gift-outline
              .control
                b-tooltip(:label="$t('customers.edit_customer_data_tooltip')" type="is-dark")
                  button.button(@click.stop="openCustomerCreateEditModal(props.row._id)"  data-qa="action-edit-customer")
                    span.icon
                      i.mdi.mdi-account-edit
              .control(v-if="props.row.visits")
                b-tooltip(:label="$t('customers.repeat_last_appt_tooltip')"  type="is-dark")
                  RepeatLastAppointmentButton(:customer="props.row"  @click="onAppointmentRebook")
</template>

<script>
import StaticApi from '@/api/static'
import CheckoutPopup from '../checkout/CheckoutPopup'
import Checkout from '../sale/Checkout'
import CustomerMerger from './CustomerMerger'
import MembershipTag from './MembershipTag'
import BirthdayTag from './BirthdayTag'
import ProfilePic from './ProfilePic'
import Details from './Details'
import GiftForm from './GiftForm'
import EstNextPurchase from './EstNextPurchase'
import NextPurchase from './NextPurchase'
import CustomerCreateEditForm from './CustomerCreateEditForm'
import TryChatbotPrompt from './TryChatbotPrompt'
import {mapState, mapGetters} from 'vuex'
import RepeatLastAppointmentButton from '../calendar/RepeatLastAppointmentButton'
import DepositGroupView from './DepositGroupView'
import AddressView from '../shared/AddressView'
import CustomerTagsEditor from './CustomerTagsEditor'

import {PRODUCTS} from '../../../constants/products'
import CustomerDetailsMobile from './CustomerDetailsMobile'
import CustomersFilter from './CustomersFilter'
const DEFAULT_FIELDS = [
  'tags',
  'birthdayTag',
  'surveys',
  'balance',
  'sales',
  'visits',
  'nextVisitDue',
  'activity',
  'depositGroup'
]
//const MED_FIELDS = ['birthdate', 'address', 'notes', 'created']
export default {
  name: 'List',
  components: {
    CustomerMerger,
    MembershipTag,
    ProfilePic,
    BirthdayTag,
    TryChatbotPrompt,
    EstNextPurchase,
    NextPurchase,
    RepeatLastAppointmentButton,
    DepositGroupView,
    AddressView
  },
  props: {
    customerId: {type: String, default: undefined}
  },
  data() {
    return {
      PRODUCTS,
      sortField: 'updated',
      sortOrder: 'desc',
      page: 1,
      perPage: 10,
      defaultSortOrder: 'desc',
      queryString: undefined,
      searching: false,
      activeDropdownCustomer: null,
      mergingCustomer: null,
      selectedCustomer: null,
      customerCategories: [],
      selectedCustomers: [],
      filters: []
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapState('auth', ['user']),
    ...mapGetters('customers', ['customers']),
    ...mapState('customers', ['total']),
    exportLink() {
      let url = this.$url('customers/export', {
        query: this.queryString,
        filters: this.filters,
        sortBy: this.sortField,
        sortDir: this.sortOrder
      })
      return url
    }
  },
  // watch: {
  //   $route() {
  //     if (this.customerId) {
  //       this.showCustomerDetails(this.customerId)
  //     }
  //   }
  // },
  async created() {
    if (this.customerId) {
      this.showCustomerDetails(this.customerId)
    }
    this.customerCategories = await StaticApi.getCustomerCategories()
    await this.fetchCustomers()
  },
  methods: {
    async fetchCustomers() {
      this.searching = true
      this.selectedCustomers = []
      try {
        await this.$store.dispatch('customers/search', {
          query: this.queryString,
          sortBy: this.sortField,
          sortDir: this.sortOrder,
          page: this.page,
          perPage: this.perPage,
          filters: this.filters
        })
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.searching = false
    },
    showField(field) {
      if (this.company.crm.fields && this.company.crm.fields.length) {
        return this.company.crm.fields.includes(field)
      }
      return DEFAULT_FIELDS.includes(field)
    },
    isWithin24hWindow(ts) {
      return ts && new Date(ts).valueOf() > new Date().valueOf() - 86400000
    },

    checkout(customer) {
      if (this.company.rewardsOnly) {
        this.$buefy.modal.open({
          parent: this,
          component: CheckoutPopup,
          props: {customerId: customer && customer._id},
          fullScreen: true,
          canCancel: ['escape']
        })
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Checkout,
          props: {customerId: customer && customer._id},
          fullScreen: true,
          hasModalCard: true,
          canCancel: ['escape']
        })
      }
    },
    onAppointmentRebook({customerId, rebookingId, date}) {
      this.$router.push({name: 'Calendar', query: {customer: customerId, rebooking: rebookingId, date}})
      // this.$buefy.modal.open({
      //   parent: this,
      //   props: {
      //     customerId,
      //     rebookingId
      //   },
      //   component: CreateEditAppointment,
      //   customClass: !this.isMobile() ? 'half-modal' : '',
      //   animation: !this.isMobile() ? 'slide-right' : 'zoom-out',
      //   hasModalCard: this.isMobile(),
      //   fullScreen: true,
      //   canCancel: ['escape', 'outside']
      // })
    },
    // newAppointment(customer) {
    //   this.$buefy.modal.open({
    //     parent: this,
    //     component: CreateEditAppointment,
    //     customClass: !this.isMobile() ? 'half-modal' : '',
    //     animation: !this.isMobile() ? 'slide-right' : 'zoom-out',
    //     hasModalCard: this.isMobile(),
    //     props: {customerId: customer && customer._id},
    //     fullScreen: true,
    //     canCancel: ['escape']
    //   })
    // },
    gift(customer) {
      this.$buefy.modal.open({
        parent: this,
        props: {customerId: customer._id},
        component: GiftForm,
        hasModalCard: true,
        canCancel: ['escape']
      })
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchCustomers()
    },
    onPageChange(page) {
      this.page = page
      this.fetchCustomers()
    },
    onPerPageChange() {
      this.fetchCustomers()
    },
    startMerge(customer) {
      this.mergingCustomer = customer
      this.activeDropdownCustomer = null
    },
    onMergeSuccess() {
      this.mergingCustomer = null
      this.fetchCustomers()
    },
    onMergeCancel() {
      this.mergingCustomer = null
    },
    removeCustomer(customer) {
      this.$buefy.dialog.confirm({
        title: this.$t('customers.remove_confirm.title'),
        message: this.$t('customers.remove_confirm.body'),
        confirmText: this.$t('customers.remove_confirm.action'),
        cancelText: this.$t('customers.remove_confirm.cancel'),
        type: 'is-danger',
        onConfirm: async () => {
          this.searching = true
          try {
            await this.$store.dispatch('customers/remove', customer._id)
            this.$buefy.toast.open({message: this.$t('customers.remove_success'), type: 'is-success', queue: false})
            this.searching = false
          } catch (err) {
            this.searching = false
            this.$handleAPIError(err)
          }
        }
      })
    },
    resetFilter() {
      this.filters = []
      this.fetchCustomers()
    },
    showFilters() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomersFilter,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        canCancel: ['escape'],
        props: {
          value: this.filters
        },
        events: {
          input: filters => {
            this.filters = filters
            this.fetchCustomers()
          }
        }
      })
    },
    toggleDropdown(customer) {
      if (customer === this.activeDropdownCustomer) {
        this.activeDropdownCustomer = null
      } else {
        this.activeDropdownCustomer = customer
      }
    },
    isFilteredBy(field) {
      return this.filters.some(f => f.key === field)
    },
    search() {
      this.fetchCustomers()
    },
    showCustomerDetails(customerId) {
      this.$buefy.modal.open({
        parent: this,
        component: this.isMobile() ? CustomerDetailsMobile : Details,
        hasModalCard: true,
        fullScreen: true,
        props: {customerId},
        canCancel: ['escape', 'outside']
        // onCancel: () => this.$router.push({name: 'Customers'}).catch(() => {}),
        // events: {
        //   close: () => this.$router.push({name: 'Customers'}).catch(() => {})
        // }
      })
    },
    selectCustomer(customer) {
      this.selectedCustomer = customer
      if (!this.mergingCustomer) {
        this.showCustomerDetails(customer._id)
      }

      // if (!this.mergingCustomer) {
      //   this.$router.push({name: 'Customers', params: {customerId: customer._id}})
      // }
    },
    openCustomerCreateEditModal(customerId) {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerCreateEditForm,
        canCancel: ['escape'],
        fullScreen: true,
        hasModalCard: true,
        props: {customerId},
        events: {
          success: () => this.fetchCustomers(),
          reopen: () => this.openCustomerCreateEditModal()
        }
      })
    },
    onFilter() {
      this.fetchCustomers()
    },

    addTags() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerTagsEditor,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        canCancel: ['escape'],
        props: {customers: this.selectedCustomers},
        events: {
          success: () => this.fetchCustomers()
        }
      })
    }
  }
}
</script>
<style scoped>
.tabs a {
  padding: 0.5em 0.5em;
}
.table td,
.table th {
  padding: 0.5em 0.5em;
}
</style>
