<template lang="pug">
button.button(type="button" @click.stop="click" :disabled="isDisabled" :class="[size, loading ? 'is-loading': '', type, bold ? 'is-bold': '']") 
  span.icon(v-if="icon")
    i.mdi(:class="`mdi-${icon}`")
  span(v-t="title" v-if="title && (!mobileIconOnly || !isMobile())")
</template>
<script>
import OpenCloseRegister from '../ledger/OpenCloseRegister'
export default {
  props: {
    title: {type: String, default: undefined},
    icon: {type: String, default: undefined},
    disabled: {type: Boolean, default: false},
    size: {type: String, default: undefined},
    loading: {type: Boolean},
    type: {type: String, default: 'is-primary'},
    permission: {type: String, default: undefined},
    product: {type: String, default: undefined},
    scope: {type: Boolean, default: false},
    bold: {type: Boolean, default: true},
    mobileIconOnly: {type: Boolean, default: false},
    cashRegister: {type: Boolean, default: false}
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.permission && !this.$rfHasPermission(this.permission)) ||
        (this.scope && (this.$rfProfileScope() || this.$rfLocationScope())) ||
        (this.product && !this.$rfSubscribed(this.product))
      )
    }
  },
  methods: {
    click() {
      if (this.cashRegister && this.$rfIsRegisterClosed()) {
        this.$buefy.dialog.confirm({
          title: this.$t('ledger.closed_register_confirm.title'),
          message: this.$t('ledger.closed_register_confirm.body'),
          cancelText: this.$t('actions.cancel'),
          confirmText: this.$t('actions.open'),
          type: 'is-black',
          onConfirm: async () => {
            this.openCloseRegister()
          }
        })
      } else {
        this.$emit('click')
      }
    },
    openCloseRegister() {
      this.$buefy.modal.open({
        parent: this,
        component: OpenCloseRegister,
        hasModalCard: true,
        canCancel: ['escape', 'outside'],
        events: {
          success: () => {
            this.$emit('click')
          }
        }
      })
    }
  }
}
</script>
<style scoped>
.button.is-bold {
  font-weight: 600;
  text-transform: uppercase;
}
</style>
