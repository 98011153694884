<template lang="pug">
  div
    b-field.mb-2(:label="showLabel && $t('sms_composer.sms_message_label') || ''" :type="errors.has('smstext') ? 'is-danger': ''" :message="errors.has('smstext') ? errors.first('smstext') : ''")
      b-input(type="textarea" style="min-height: 5em;" v-model="sms" ref="textInput" data-vv-as="sms text" v-validate="contentValidation" name="smstext" :maxlength="maxLength")
    .mb-2(v-if="dynamicFields") 
      .tags
        .tag.is-success(@click="insertField(val)" v-for="val in dynamicFields" :key="val") {{ val }}
    p(v-if="!limitToOne && sms && showCount") 
      strong {{$t('sms_composer.messages_count_label')}}
      strong.is-pulled-right {{smsCharacters.messages}}
    note(size="is-small" type="is-warning" v-if="sms" v-show="smsCharacters.encoding === 'UTF16'") {{$t('sms_composer.sms_unicode_note')}}            
    note(size="is-small" type="is-info" v-if="!transactional && company.smsOptOut") Opt out link not included - will take approximatelly 27 symbols.

    
    .mt-3(v-if="!noHelp")   
      h4.title.is-6 {{$t('sms_composer.placeholders_title')}}
      p.subtitle.is-7 {{$t('sms_composer.placeholders_subtitle')}}      
      .list.is-clickable
        .list-item(@click="sms += ' {{firstName}}'") 
          span.tag.is-success(v-pre) {{firstName}}
          small.is-pulled-right {{$t('sms_composer.placeholder_firstname')}}
        .list-item(@click="sms += ' {{lastName}}'") 
          span.tag.is-success(v-pre) {{lastName}}
          small.is-pulled-right {{$t('sms_composer.placeholder_lastname')}}
        .list-item(@click="sms += ' {{balance}}'") 
          span.tag.is-success(v-pre) {{balance}}
          small.is-pulled-right {{$t('sms_composer.placeholder_balance')}}
        .list-item(@click="sms += ' {{visits}}'") 
          span.tag.is-success(v-pre) {{visits}}
          small.is-pulled-right {{$t('sms_composer.placeholder_visits')}}
      p.help.is-danger {{$t('sms_composer.message_length_warning')}}
</template>
<script>
import {mapState} from 'vuex'
import SmsCounter from '../../../shared/smsHelper'
export default {
  props: {
    value: {type: String, default: undefined},
    transactional: {type: Boolean, default: false},
    limitToOne: {type: Boolean, default: false},
    noHelp: {type: Boolean, default: false},
    dynamicFields: {type: Array, default: undefined},
    hasLink: {type: Boolean, default: true},
    bridgeLink: {type: String, default: ''},
    showLabel: {type: Boolean, default: true},
    showCount: {type: Boolean, default: true}
  },
  inject: ['$validator'],
  data() {
    return {
      sms: ''
    }
  },
  computed: {
    ...mapState('company', ['company']),
    contentValidation() {
      if (this.hasLink) {
        return 'required|hasLink'
      }
      return 'required'
    },
    smsCharacters() {
      if (this.sms) {
        return SmsCounter.count(this.sms + this.linkLength)
      }
      return undefined
    },
    maxLength() {
      let multiplier
      if (!this.sms) {
        return undefined
      }
      if (this.limitToOne) {
        multiplier = 1
      } else {
        multiplier = this.smsCharacters.remaining === 0 ? this.smsCharacters.messages + 1 : this.smsCharacters.messages
      }

      return this.smsCharacters.per_message * multiplier - this.linkLength
    },
    linkLength() {
      if (!this.sms) {
        return
      }
      let linkPlaceholder = this.sms.match(/{{\s*link\s*}}/g)
      if (linkPlaceholder) {
        return (this.bridgeLink ? ' ' + this.bridgeLink : '').length - linkPlaceholder[0].length
      }
      return 0
    }
  },
  watch: {
    value(newVal) {
      this.sms = newVal
    },
    sms() {
      this.$emit('input', this.sms)
    }
  },
  mounted() {
    this.$refs.textInput.focus()
  },
  created() {
    this.sms = this.value
  },
  methods: {
    insertField(val) {
      this.sms += ' ' + val
    }
    // insertAtPosition(value) {
    //   let el = this.$refs.textInput.$el.children[0]
    //   console.log(el)
    //   if (el.selectionStart || el.selectionStart == '0') {
    //     var startPos = el.selectionStart
    //     var endPos = el.selectionEnd
    //     el.value = el.value.substring(0, startPos) + value + el.value.substring(endPos, el.value.length)
    //   } else {
    //     el.value += value
    //   }
    // }
  }
}
</script>
