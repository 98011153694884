import Vue from 'vue'
import {PRODUCTS} from '../../constants/products'

export default {
  permissions: ['no', 'low', 'med', 'high', 'owner'],
  durations: [
    undefined,
    5,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    55,
    60,
    65,
    70,
    75,
    80,
    85,
    90,
    95,
    100,
    105,
    110,
    115,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    195,
    210,
    225,
    240,
    270,
    300,
    330,
    360,
    390,
    420,
    450,
    480,
    540,
    600,
    660,
    720
  ],
  cc: ['master', 'visa', 'amex', 'discover'],
  async getCreditCardTypes() {
    return this.cc
  },
  getBusinessCategories() {
    return Vue.http.get('static/businessCategories').then(res => res.body)
  },
  getCustomerCategories() {
    return new Promise(resolve => {
      resolve([
        {value: 'all', icon: 'account-group'},
        {
          value: 'customers',
          icon: 'account-check-outline',
          type: 'has-text-info'
        },
        {
          value: 'new',
          icon: 'account-alert-outline',
          type: 'has-text-info'
        },
        {
          value: 'noshows',
          icon: 'calendar-alert',
          type: 'has-text-danger'
        },
        {
          value: 'canceled',
          icon: 'calendar-remove-outline',
          type: 'has-text-danger'
        },
        {
          value: 'birthdays',
          icon: 'cake-variant',
          type: 'has-text-info'
        },
        {
          value: 'unhappy',
          icon: 'emoticon-sad-outline',
          type: 'has-text-danger',
          product: PRODUCTS.SURVEYS
        },
        {value: 'members', icon: 'card-account-details-outline', product: PRODUCTS.REWARDS_PROGRAM},
        {value: 'prospects', icon: 'account-question-outline', product: PRODUCTS.REWARDS_PROGRAM},
        {
          value: 'happy',
          icon: 'emoticon-happy-outline',
          type: 'has-text-success',
          product: PRODUCTS.SURVEYS
        },
        {
          value: 'loyal',
          icon: 'repeat',
          type: 'has-text-success',
          product: PRODUCTS.CHURN_PREDICTION
        },
        {
          value: 'lost',
          icon: 'account-remove-outline',
          type: 'has-text-danger',
          product: PRODUCTS.CHURN_PREDICTION
        },
        {
          value: 'referred',
          icon: 'account-plus-outline',
          type: 'has-text-success',
          product: PRODUCTS.REFERRAL_PROGRAM
        }
      ])
    })
  },
  getAccessPermissions() {
    return this.permissions
  },
  getChatTones() {
    return Vue.http.get('static/chatTones').then(res => res.body)
  },
  getAmenities() {
    return Vue.http.get('static/amenities').then(res => res.body)
  },
  async getLanguages() {
    return ['en_US', 'es_ES', 'ru_RU', 'hy_AM']
  },
  async getUnits() {
    return ['pcs', 'ml', 'mg', 'gr', 'l', 'kg', 'cm', 'in']
  },
  getAutomationTypes() {
    return Vue.http.get('static/automationTypes').then(res => res.body)
  },
  getTemplateTags() {
    return new Promise(resolve => {
      resolve([
        {value: 'girly', title: 'Girly'},
        {value: 'manly', title: 'Manly'},
        {value: 'direct', title: 'Direct'},
        {value: 'official', title: 'Official'},
        {value: 'neutral', title: 'Neutral'},
        {value: 'funny', title: 'Funny'}
      ])
    })
  },
  getRewardTypes() {
    return new Promise(resolve => {
      resolve([
        {value: 'currency', title: 'Cash'},
        {value: 'points', title: 'Points'}
      ])
    })
  },
  async getDurations() {
    return this.durations
  },
  getLoyaltyProgramThemes() {
    return Vue.http.get('static/loyaltyProgramThemes').then(res => res.body)
  },
  getInvitationArtworks() {
    return Vue.http.get('static/invitationArtworks').then(res => res.body)
  }
}
