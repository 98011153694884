<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'phone_actions.title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
      
  section.modal-card-body.p-0
    .p-3.has-border-bottom(v-if="customer")
      customer-tiny-preview(:value="customer" size="large")
    .p-4
      p.title.is-6(v-t="'phone_actions.description'")
      .buttons.is-centered
        a.button.is-medium.is-outlined.is-dark.is-fullwidth(v-if="isMobile()" :href="`tel:${phone}`" target="_blank") 
          span.icon
            i.mdi.mdi-phone
          span {{ $t('phone_actions.call') }}
        a.button.is-medium.is-outlined.is-dark.is-fullwidth(v-if="customer" @click="openMessageComposer" target="_blank") 
          span.icon
            i.mdi.mdi-message-outline
          span {{ $t('phone_actions.sms') }}
        a.button.is-medium.is-outlined.is-dark.is-fullwidth(:href="`https://wa.me/${phone}`" target="_blank") 
          span.icon
            i.mdi.mdi-whatsapp
          span {{ $t('phone_actions.whatsapp') }}
      
  footer.modal-card-foot
    button.button(@click="$emit('close')") {{ $t('actions.close') }}  
</template>
<script>
import CustomerSMSModalVue from '../customers/CustomerSMSModal.vue'
export default {
  name: 'PhoneActionModal',
  props: {
    phone: {type: String, required: true},
    customer: {
      type: Object,
      default: undefined
    }
  },
  methods: {
    openMessageComposer() {
      this.$emit('close')
      this.$buefy.modal.open({
        parent: this.$parent,
        component: CustomerSMSModalVue,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        props: {customer: this.customer},
        canCancel: ['escape']
      })
    }
  }
}
</script>
