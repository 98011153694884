<template lang="pug">
span(v-if="voucher" @click.stop="openDetails" :class="details? 'is-clickable' : ''")
  span {{voucher.name}}
  template(v-if="code")
    |  ·  
    b {{voucher.code}}
  template(v-if="invoice")
    |  ·  
    span \#{{voucher.invoice}}
  template(v-if="showNominalValue")
    |  -      
    currency-view(:value="voucher.value" )
    template(v-if="showRemainder")
      |  (
      currency-view(:value="voucher.remaining")
      | )
span.has-text-grey(v-else) {{$t('vouchers.removed_voucher')}}

</template>
<script>
import VouchersApi from '@/api/vouchers'
import isString from 'lodash/isString'
import VoucherDetails from './VoucherDetails.vue'
export default {
  name: 'VoucherNameView',
  props: {
    value: {type: [String, Object], required: true},
    code: {type: Boolean, default: true},
    invoice: {type: Boolean, default: false},
    details: {type: Boolean, default: true},
    showNominalValue: {type: Boolean, default: false},
    showRemainder: {type: Boolean, default: false}
  },
  data() {
    return {
      voucher: undefined
    }
  },
  async created() {
    if (isString(this.value)) {
      this.voucher = await VouchersApi.getVoucherById(this.value)
    } else {
      this.voucher = this.value
    }
  },
  methods: {
    openDetails() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        props: {value: this.voucher},
        component: VoucherDetails,
        canCancel: ['outside', 'escape', 'x']
      })
    }
  }
}
</script>
