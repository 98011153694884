<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'customers.filters_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    .notification.is-filter.has-text-centered(v-if="!filters.length" v-t="'audience.no_filters_applied'")
    .list
      .list-item.is-flex.is-align-items-center.is-filter(v-for="(filter, idx) in filters" :key="filter.key" :style="{'flex-wrap': isMobile() ? 'wrap' : 'no-wrap'}")
        
        .mr-3.has-text-primary {{filterFields[filter.key].label}}
        template(v-if="filterFields[filter.key].type === 'select'")     
          .mr-3 
            b-select(v-model="filter.operator" rounded)
              option(value="eq") {{$t('operators.is')}}
          .mr-3
            
            b-select(v-model="filter.value" v-if="filterFields[filter.key].translateKey")
              option(v-for="value in filterFields[filter.key].values" :value="value") {{$t(`${filterFields[filter.key].translateKey}.${value}`)}}
            b-select(v-model="filter.value" v-else)
              option(v-for="{label, value} in filterFields[filter.key].values" :value="value") {{label}}
        template(v-if="filterFields[filter.key].type === 'number'")     
          .mr-3
            b-select(v-model="filter.operator" rounded)
              option(value="eq") {{$t('operators.eq')}}
              option(value="lt") {{$t('operators.lt')}}
              option(value="lte") {{$t('operators.lte')}}
              option(value="gt") {{$t('operators.gt')}}
              option(value="gte") {{$t('operators.gte')}}
              option(value="btw") {{$t('operators.btw')}}
          .mr-3
            b-input(v-model.number="filter.value" :ref="`input_${filter.key}`" type="number" :placeholder="filterFields[filter.key].placeholder")
          template(v-if="filter.operator === 'btw'")
            .mr-3
              span -
            .mr-3
              b-input(v-model.number="filter.value2" type="number" :placeholder="filterFields[filter.key].placeholder")
        template(v-else-if="filterFields[filter.key].type === 'date'")
          .mr-3
            b-select(v-model="filter.operator" rounded)
              //optgroup(:label="$t('periods.past')")
              option(v-for="p in filterFields[filter.key].periods" :value="p") {{$t(`periods.${p}`)}}
              
              optgroup(:label="$t('periods.custom')")
                option(value="lt") {{$t('operators.before')}}
                option(value="gt") {{$t('operators.after')}}
                //- option(value="days_before") {{$t('operators.days_before')}}
                //- option(value="days_after") {{$t('operators.days_after')}}
                option(value="btw") {{$t('operators.btw')}}
          template(v-if="filter.operator === 'btw' || filter.operator === 'gt' || filter.operator === 'lt'")
            .mr-3
              localized-datepicker(v-model="filter.value" :placeholder="filterFields[filter.key].after.placeholder" append-to-body)
            template(v-if="filter.operator === 'btw'")
              .mr-3
                span -
              .mr-3 
                localized-datepicker(v-model="filter.value2" :placeholder="filterFields[filter.key].before.placeholder" append-to-body)
        
        template(v-else-if="filterFields[filter.key].type === 'tags'")
          .mr-3
            b-select(v-model="filter.operator" rounded)
              option(value="in") {{$t('operators.contains')}}
              option(value="nin") {{$t('operators.not_contains')}}
          .mr-3
            b-taginput(
              v-model="filter.value"
              :data="company.crm.tags"
              type="is-primary"
              attached
              autocomplete
              ellipsis
              open-on-focus
              icon="label"
              :placeholder="filterFields[filter.key].placeholder")
        template(v-else-if="filterFields[filter.key].type === 'exists'")
          .mr-3
            b-select(v-model="filter.operator" rounded)
              option(value="exists") {{$t('operators.is_not_empty')}}
              option(value="not_exists") {{$t('operators.is_empty')}}

        //template(v-else-if="filterFields[filter.key].type === 'services'")
          .mr-3
            b-select(v-model="filter.operator" rounded)
              option(value="in") {{$t('operators.contains')}}
              option(value="nin") {{$t('operators.not_contains')}}
          .mr-3
            b-select(v-model="filter.value" multiple :placeholder="$t('audience.services_booked_placeholder')")
              option() 1
              option() 2
              option() 3
              option() 4

        
        
        .filter-remove(style="margin-left: auto")
          span.icon.is-clickable(@click="removeFilter(idx)")
            i.mdi.mdi-trash-can-outline
    hr
    //p.title.is-5 Select filter to add
    //.tags
      span.tag.is-clickable.is-rounded.is-medium(v-for="(field, idx) in availableFields" :key="field" @click="addFilter(field)") {{filterFields[field].label}}
    div(v-if="availableFields.length")
      //- div.mr-3
      //-   //span.icon.has-text-grey.is-medium
      //-     i.mdi.mdi-plus-circle-outline.mdi-24px
      //-   span Select filter to add
      b-field()
        b-select(v-model="newFilter" @input="addFilter" :placeholder="$t('audience.add_filter')" )
          optgroup(v-for="group in availableFilterGroups" :label="$t(`audience.filter_groups.${group.group}`)")
            option(v-for="field in group.fields" :value="field") {{filterFields[field].label}}
          //option(v-for="field in availableFields" :value="field") {{filterFields[field].label}}
    
  footer.modal-card-foot
    .buttons
      action-button(@click="submit" :loading="submitting" title="actions.apply")
      button.button.is-outlined(@click="$emit('close')") {{$t('actions.cancel')}}
  
</template>

<script>
import {mapState} from 'vuex'
import GENDERS from '../../../constants/genders'
import FILTERS from '../../../constants/customerFilterFields'
import {PRODUCTS} from '../../../constants/products'
const futurePeriods = [
  'tomorrow',
  'next3',
  'next7',
  'next14',
  'next28',
  'next30',
  'next90',
  'thisWeek',
  'thisMonth',
  'thisQuarter',
  'thisYear',
  'nextWeek',
  'nextMonth',
  'nextQuarter',
  'nextYear'
]
const pastPeriods = [
  'today',
  'yesterday',
  'last90',
  'last30',
  'last28',
  'last14',
  'last7',
  'last3',
  'mtd',
  'ytd',
  'lastWeek',
  'lastMonth',
  'lastQuarter',
  'lastYear'
]
const FILTER_GROUPS = [
  {
    group: 'sales',
    fields: [FILTERS.sales, FILTERS.balance, FILTERS['stats.avgSale']]
  },
  {
    group: 'data',
    fields: [FILTERS.gender, FILTERS.birthdate, FILTERS.phone, FILTERS.email, FILTERS.lng, FILTERS.tags]
  },
  {
    group: 'visits',
    fields: [
      FILTERS.lastVisit,
      FILTERS.nextVisit,
      FILTERS.nextVisitDue,
      FILTERS.visits,
      FILTERS.canceled,
      FILTERS.noshows,
      FILTERS['stats.tbp']
    ]
  },
  {
    product: PRODUCTS.REWARDS_PROGRAM,
    group: 'loyalty_program',
    fields: [FILTERS.balance, FILTERS.membership]
  }
  // {
  //   group: 'surveys',
  //   fields: [FILTERS.avgRating, FILTERS.nps]
  // }
]

export default {
  name: 'CustomersFilter',
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      submitting: false,
      filters: [],
      FILTER_GROUPS,
      newFilter: null,
      pastPeriods,
      futurePeriods
    }
  },
  computed: {
    ...mapState('company', ['company']),
    availableFields() {
      return Object.keys(this.filterFields).filter(field => !this.filters.find(f => f.key === field))
    },
    availableFilterGroups() {
      return this.FILTER_GROUPS.map(group => {
        return {
          group: group.group,
          fields: group.fields.filter(field => !this.filters.find(f => f.key === field))
        }
      }).filter(group => group.fields.length)
    },

    filterFields() {
      return {
        lng: {
          label: this.$t('audience.language_label'),
          type: 'select',
          icon: 'target',
          values: this.company.languages,
          translateKey: 'languages',
          defaultValue: this.company.defaultLanguage
        },
        gender: {
          type: 'select',
          label: this.$t('audience.gender_label'),
          values: Object.values(GENDERS),
          translateKey: 'genders',
          defaultValue: Object.values(GENDERS)[0]
        },
        [FILTERS.membership]: {
          type: 'select',
          label: this.$t('audience.membership_label'),
          values: this.company.membership.titles.map((t, idx) => {
            return {value: idx, label: t}
          }),
          defaultValue: 0
        },
        // [FILTERS.avgRaring]: {
        //   type: 'select',
        //   label: this.$t('audience.gender_label'),
        //   values: [1, 2, 3, 3.5, 4, 4.5, 5],
        //   translateKey: 'genders'
        // },

        // {
        //   key: 'phonePrefix',
        //   label: this.$t('audience.phone_prefix_label'),
        //   type: 'input',
        //   placeholder: this.$t('audience.phone_prefix_placeholder')
        // },
        birthdate: {
          type: 'date',
          label: this.$t('audience.birthday_label'),
          after: {placeholder: this.$t('audience.after_last_visit_placeholder')},
          before: {placeholder: this.$t('audience.before_last_visit_placeholder')},
          format: 'MMDD',
          defaultOperator: 'nextWeek',
          periods: ['today', 'tomorrow', 'nextWeek', 'nextMonth', 'thisMonth', 'thisWeek', 'next3', 'next7', 'next14']
        },
        lastVisit: {
          type: 'date',
          label: this.$t('audience.last_visit_label'),
          after: {placeholder: this.$t('audience.after_last_visit_placeholder')},
          before: {placeholder: this.$t('audience.before_last_visit_placeholder')},
          defaultOperator: 'lastMonth',
          periods: ['today', 'yesterday', 'lastWeek', 'lastMonth', 'thisMonth', 'thisWeek', 'last3', 'last7', 'last14']
        },
        nextVisit: {
          type: 'date',
          label: this.$t('audience.next_visit_label'),
          after: {placeholder: this.$t('audience.after_last_visit_placeholder')},
          before: {placeholder: this.$t('audience.before_last_visit_placeholder')},
          defaultOperator: 'nextWeek',
          periods: [
            'today',
            'tomorrow',
            'nextWeek',
            'nextMonth',
            'thisMonth',
            'thisWeek',
            'next3',
            'next7',
            'next14',
            'next28',
            'next30',
            'next90'
          ]
        },
        nextVisitDue: {
          type: 'date',
          label: this.$t('audience.next_visit_due_label'),
          after: {placeholder: this.$t('audience.after_last_visit_placeholder')},
          before: {placeholder: this.$t('audience.before_last_visit_placeholder')},
          defaultOperator: 'nextWeek',
          periods: [
            'today',
            'tomorrow',
            'nextWeek',
            'nextMonth',
            'thisMonth',
            'thisWeek',
            'next3',
            'next7',
            'next14',
            'next28',
            'next30',
            'next90'
          ]
        },
        visits: {
          type: 'number',
          label: this.$t('audience.visits_label'),
          min: {placeholder: this.$t('audience.min_visits_placeholder')},
          max: {placeholder: this.$t('audience.max_visits_placeholder')},
          defaultOperator: 'gt',
          defaultValue: 0
        },
        canceled: {
          type: 'number',
          label: this.$t('audience.canceled_label'),
          min: {placeholder: this.$t('audience.min_visits_placeholder')},
          max: {placeholder: this.$t('audience.max_visits_placeholder')},
          defaultOperator: 'gt',
          defaultValue: 0
        },
        noshows: {
          type: 'number',
          label: this.$t('audience.noshows_label'),
          min: {placeholder: this.$t('audience.min_visits_placeholder')},
          max: {placeholder: this.$t('audience.max_visits_placeholder')},
          defaultOperator: 'gt',
          defaultValue: 0
        },

        sales: {
          type: 'number',
          label: this.$t('audience.sales_label'),
          min: {placeholder: this.$t('audience.min_sales_placeholder')},
          max: {placeholder: this.$t('audience.max_sales_placeholder')},
          format: 'currency',
          defaultOperator: 'gt',
          defaultValue: 0
        },
        balance: {
          type: 'number',
          label: this.$t('audience.balance_label'),
          min: {placeholder: this.$t('audience.min_sales_placeholder')},
          max: {placeholder: this.$t('audience.max_sales_placeholder')},
          defaultOperator: 'gt',
          defaultValue: 0
        },
        'stats.tbp': {
          type: 'number',
          label: this.$t('audience.tbp_label'),
          min: {placeholder: this.$t('audience.min_sales_placeholder')},
          max: {placeholder: this.$t('audience.max_sales_placeholder')},
          defaultOperator: 'gt',
          defaultValue: 0
        },
        'stats.avgSale': {
          type: 'number',
          label: this.$t('audience.avg_sales_label'),
          min: {placeholder: this.$t('audience.min_sales_placeholder')},
          max: {placeholder: this.$t('audience.max_sales_placeholder')},
          defaultOperator: 'gt',
          defaultValue: 0
        },
        tags: {
          type: 'tags',
          label: this.$t('audience.tags_label'),
          min: {placeholder: this.$t('audience.min_sales_placeholder')},
          max: {placeholder: this.$t('audience.max_sales_placeholder')},
          defaultOperator: 'in'
        },
        phone: {
          type: 'exists',
          label: this.$t('audience.phone_label'),
          values: [true, false],
          defaultOperator: 'exists',
          defaultValue: true
        },
        email: {
          type: 'exists',
          label: this.$t('audience.email_label'),
          values: [true, false],
          defaultOperator: 'exists',
          defaultValue: true
        }
        // favServices: {type: 'services', label: this.$t('audience.services_booked_label'), defaultOperator: 'in'},
        // favStaff: {type: 'staff', label: this.$t('audience.staff_booked_label'), defaultOperator: 'in'}
      }
    }
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.filters = [...newVal]
      }
    }
  },
  created() {
    this.filters = [...this.value]
  },
  methods: {
    addFilter(key) {
      if (!key) {
        return
      }
      let field = this.filterFields[key]
      this.filters.push({
        key: key,
        format: field.format,
        dataType: field.type,
        operator: field.defaultOperator || 'eq',
        value: field.defaultValue !== undefined ? field.defaultValue : field.type === 'date' ? new Date() : undefined
      })

      this.$nextTick().then(() => {
        if (this.$refs[`input_${key}`]) {
          this.$refs[`input_${key}`][0].focus()
        }
        this.newFilter = null
      })
    },
    removeFilter(index) {
      this.filters.splice(index, 1)
    },
    async submit() {
      let valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.submitting) {
        return
      }
      this.submitting = true

      try {
        this.$emit(
          'input',
          this.filters.filter(f => f.dataType !== 'number' || (f.value !== undefined && f.value !== ''))
        )
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
