import Vue from 'vue'
import {
  //SET_APPOINTMENTS,
  SET_APPOINTMENT,
  SET_CUSTOMER_APPOINTMENTS,
  SET_APPOINTMENTS_BY_DATE,
  REMOVE_APPOINTMENT
} from './mutationTypes'
import AppointmentsApi from '@/api/appointments'
import dayjs from 'dayjs'
import APPOINTMENT_STATUS from '../../constants/appointmentStatus'
import SurveysApi from '@/api/surveys'

export default {
  namespaced: true,
  state: {
    appointmentsMap: {},
    appointmentsOrder: [],
    customerAppointmentsOrder: [],
    appointmentsByDateOrder: [],
    currentCustomerId: undefined,
    currentDate: undefined,
    currentLocationId: undefined,
    week: undefined
    // filter: {},
    // total: 0
  },
  getters: {
    appointmentById(state) {
      return id => state.appointmentsMap[id]
    },
    customerAppointments(state) {
      return state.customerAppointmentsOrder
        .map(c => state.appointmentsMap[c])
        .sort((a, b) => {
          return new Date(b.start) - new Date(a.start)
        })
    },
    customerUpcomingAppointments(state) {
      return state.customerAppointmentsOrder
        .map(c => state.appointmentsMap[c])
        .filter(
          a =>
            new Date(a.start) > new Date() &&
            a.status !== APPOINTMENT_STATUS.CANCELED &&
            a.status !== APPOINTMENT_STATUS.INCOMPLETE &&
            a.status !== APPOINTMENT_STATUS.COMPLETED &&
            a.status !== APPOINTMENT_STATUS.NOSHOW
        )
        .sort((a, b) => {
          return new Date(b.start) - new Date(a.start)
        })
    },
    busyStaffAtLocation(state) {
      return locationId => {
        let staffMembers = state.appointmentsByDateOrder
          .map(c => state.appointmentsMap[c])
          .filter(
            a =>
              a.status !== APPOINTMENT_STATUS.CANCELED &&
              a.status !== APPOINTMENT_STATUS.INCOMPLETE &&
              a.location === locationId
          )
          .reduce((result, appointment) => {
            appointment.services.forEach(s => {
              if (s.staff) {
                result[s.staff] = true
              }
            })
            return result
          }, {})
        return Object.keys(staffMembers)
      }
    },
    staffAppointments(state, getters, rootState) {
      return (staffIds, locationId) => {
        return state.appointmentsByDateOrder
          .map(c => state.appointmentsMap[c])
          .filter(
            a =>
              !!a.services.find(s => staffIds.includes(s.staff)) &&
              a.status !== APPOINTMENT_STATUS.CANCELED &&
              a.status !== APPOINTMENT_STATUS.INCOMPLETE
          )
          .reduce((result, appointment) => {
            const editable =
              appointment.location === locationId &&
              appointment.status !== APPOINTMENT_STATUS.COMPLETED &&
              appointment.status !== APPOINTMENT_STATUS.NOSHOW

            const byServices = appointment.services.map(s => {
              let color =
                (rootState.services &&
                  rootState.services.servicesMap &&
                  rootState.services.servicesMap[s.service] &&
                  rootState.services.servicesMap[s.service].color) ||
                appointment.color
              return {
                _id: s._id,
                customer: appointment.customer,
                service: {...s},
                start: dayjs(s.start).format('YYYY-MM-DD HH:mm'),
                end: dayjs(s.start)
                  .add(s.duration + (s.blockedTimeAfter || 0), 'minute')
                  .format('YYYY-MM-DD HH:mm'),
                status: appointment.status,
                split: s.staff,
                preferred: s.preferred,
                appointment,

                class: `split-${appointment.status} ${
                  appointment.location !== locationId ? 'split-different-location' : ''
                } ${color ? 'split-color-' + color : ''} ${s.preferred ? 'split-preferred' : ''}`,
                draggable: editable,
                resizable: editable
              }
            })
            return result.concat(byServices)
          }, [])
      }
    },
    resourceAppointments(state, getters, rootState) {
      return locationId => {
        return state.appointmentsByDateOrder
          .map(c => state.appointmentsMap[c])
          .filter(
            s =>
              (!locationId || s.location === locationId) &&
              s.status !== APPOINTMENT_STATUS.CANCELED &&
              s.status !== APPOINTMENT_STATUS.INCOMPLETE
          )
          .reduce((result, appointment) => {
            const editable =
              appointment.status !== APPOINTMENT_STATUS.COMPLETED && appointment.status !== APPOINTMENT_STATUS.NOSHOW

            const byServices = appointment.services
              .filter(s => s.resource)
              .map(s => {
                let color =
                  (rootState.services &&
                    rootState.services.servicesMap &&
                    rootState.services.servicesMap[s.service] &&
                    rootState.services.servicesMap[s.service].color) ||
                  appointment.color
                return {
                  _id: s._id,
                  customer: appointment.customer,
                  service: {...s},
                  start: dayjs(s.start).format('YYYY-MM-DD HH:mm'),
                  end: dayjs(s.start)
                    .add(s.duration + (s.blockedTimeAfter || 0), 'minute')
                    .format('YYYY-MM-DD HH:mm'),
                  status: appointment.status,
                  split: s.resource,
                  preferred: s.preferredResource,
                  appointment,

                  class: `split-${appointment.status} ${color ? 'split-color-' + color : ''} ${
                    s.preferredResource ? 'split-preferred' : ''
                  }`,
                  draggable: editable,
                  resizable: editable
                }
              })
            return result.concat(byServices)
          }, [])
      }
    }
  },
  mutations: {
    [SET_CUSTOMER_APPOINTMENTS](state, {appointments, customerId}) {
      for (let appointment of appointments) {
        Vue.set(state.appointmentsMap, appointment._id, appointment)
      }
      state.customerAppointmentsOrder = appointments.map(c => c._id)
      state.currentCustomerId = customerId
    },
    // [SET_APPOINTMENTS](state, {appointments, total, filter}) {
    //   for (let appointment of appointments) {
    //     Vue.set(state.appointmentsMap, appointment._id, appointment)
    //   }
    //   state.appointmentsOrder = appointments.map(c => c._id)
    //   state.total = total
    //   state.filter = filter
    // },
    [SET_APPOINTMENTS_BY_DATE](state, {appointments, date, week, locationId}) {
      for (let appointment of appointments) {
        Vue.set(state.appointmentsMap, appointment._id, appointment)
      }
      state.appointmentsByDateOrder = appointments.map(c => c._id)
      state.currentDate = date
      state.week = !!week
      state.currentLocationId = locationId
    },

    [SET_APPOINTMENT](state, appointment) {
      let current = state.appointmentsMap[appointment._id] || {}
      for (let prop in appointment) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, appointment[prop])
        } else {
          current[prop] = appointment[prop]
        }
      }
      Vue.set(state.appointmentsMap, appointment._id, appointment)

      if (
        state.currentDate &&
        state.currentDate.valueOf() ===
          dayjs(appointment.start)
            .startOf('day')
            .toDate()
            .valueOf() &&
        state.appointmentsByDateOrder &&
        !state.appointmentsByDateOrder.includes(appointment._id)
      ) {
        state.appointmentsByDateOrder.push(appointment._id)
      }

      if (state.currentCustomerId && appointment.customer !== state.currentCustomerId) {
        let idx = state.customerAppointmentsOrder.indexOf(appointment._id)
        if (idx !== -1) state.customerAppointmentsOrder.splice(idx, 1)
      } else if (state.currentCustomerId && !state.customerAppointmentsOrder.includes(appointment._id)) {
        state.customerAppointmentsOrder.push(appointment._id)
      }
    },
    [REMOVE_APPOINTMENT](state, apptId) {
      delete state.appointmentsMap[apptId]

      let idx = state.appointmentsOrder.indexOf(apptId)
      if (idx !== -1) {
        state.appointmentsOrder.splice(idx, 1)
      }

      idx = state.appointmentsByDateOrder.indexOf(apptId)
      if (idx !== -1) {
        state.appointmentsByDateOrder.splice(idx, 1)
      }
    }
  },
  actions: {
    async fetchAppointmentsByDate({commit, state, dispatch, rootState}, {date, week, locationId}) {
      date = date || state.currentDate
      locationId = locationId || state.currentLocationId
      week = week !== undefined ? week : state.week
      let weekStart
      if (dayjs(date).day() < rootState.company.company.weekStart) {
        weekStart = dayjs(date)
          .startOf('day')
          .day(rootState.company.company.weekStart - 7)
          .toDate()
      } else {
        weekStart = dayjs(date)
          .startOf('day')
          .day(rootState.company.company.weekStart)
          .toDate()
      }
      const fromDate = week ? weekStart : date
      const params = {
        from: dayjs(fromDate)
          .startOf('day')
          .toDate(),
        to: dayjs(fromDate)
          .add(week ? 7 : 0, 'days')
          .endOf('day')
          .toDate(),
        location: locationId
      }
      commit(SET_APPOINTMENTS_BY_DATE, {appointments: [], date, week, locationId})
      let {appointments} = await dispatch('_fetchAppointments', params)
      commit(SET_APPOINTMENTS_BY_DATE, {appointments, date, week, locationId})
      return appointments
    },
    async fetchCustomerAppointments({commit, dispatch}, customerId) {
      commit(SET_CUSTOMER_APPOINTMENTS, {appointments: [], customerId})
      let {appointments} = await dispatch('_fetchAppointments', {customer: customerId})
      commit(SET_CUSTOMER_APPOINTMENTS, {appointments, customerId})
      return appointments
    },
    // async fetchAppointments({commit, state, dispatch}, filter) {
    //   filter = filter || {...state.filter}
    //   if (filter.date) {
    //     filter.from = dayjs(filter.date)
    //       .startOf('day')
    //       .toDate()
    //     filter.to = dayjs(filter.date)
    //       .endOf('day')
    //       .toDate()
    //   }
    //   let {appointments, total} = await dispatch('_fetchAppointments', filter)
    //   commit(SET_APPOINTMENTS, {appointments, total, filter})
    //   return appointments
    // },

    async _fetchAppointments(_context, params) {
      let {appointments, total} = await AppointmentsApi.list(params)
      if (!appointments.length) {
        return {appointments, total}
      }
      let saleIds = appointments
        .filter(s => s.status === APPOINTMENT_STATUS.COMPLETED && s.customer && s.sale)
        .map(s => s.sale)
      if (saleIds.length) {
        let surveys = await SurveysApi.getSurveys({
          customerId: params.customer,
          saleIds,
          type: 'feedback'
        })
        for (let appointment of appointments) {
          appointment.surveys = surveys.filter(s => s.sale === appointment.sale)
        }
      }

      return {appointments, total}
    },

    async fetchAppointmentById({state, commit}, {id, force}) {
      if (!state.appointmentsMap[id] || force) {
        let appointment = await AppointmentsApi.getById(id)
        let surveys
        if (appointment.status === APPOINTMENT_STATUS.COMPLETED && appointment.customer && appointment.sale) {
          surveys = await SurveysApi.getSurveys({customerId: appointment.customer, saleIds: [appointment.sale]})
        } else {
          surveys = []
        }
        appointment.surveys = surveys
        commit(SET_APPOINTMENT, appointment)
      }
      return state.appointmentsMap[id]
    },
    async terminateAppointment({state, commit}, {appointmentId, data}) {
      let appointment = await AppointmentsApi.terminate(appointmentId, data)
      commit(SET_APPOINTMENT, appointment)
      return state.appointmentsMap[appointment._id]
    },
    async recoverAppointment({state, commit}, appointmentId) {
      let appointment = await AppointmentsApi.recover(appointmentId)
      commit(SET_APPOINTMENT, appointment)
      return state.appointmentsMap[appointment._id]
    },
    async removeTimeBlock({commit}, appointmentId) {
      await AppointmentsApi.removeTimeBlock(appointmentId)
      commit(REMOVE_APPOINTMENT, appointmentId)
    },

    async createAppointment({commit}, data) {
      let appointment = await AppointmentsApi.create(data)
      commit(SET_APPOINTMENT, appointment)
      return appointment
    },
    async updateAppointment({commit}, {appointmentId, data}) {
      let appointment = await AppointmentsApi.update(appointmentId, data)
      commit(SET_APPOINTMENT, appointment)
      return appointment
    },
    async changeStatus({commit}, {appointmentId, status}) {
      let appointment = await AppointmentsApi.changeStatus(appointmentId, status)
      commit(SET_APPOINTMENT, appointment)
      return appointment
    },
    async editNotes({commit}, {appointmentId, notes}) {
      let appointment = await AppointmentsApi.editNotes(appointmentId, notes)
      commit(SET_APPOINTMENT, appointment)
      return appointment
    },
    async blockTime({commit}, data) {
      let appointment = await AppointmentsApi.blockTime(data)
      commit(SET_APPOINTMENT, appointment)
      return appointment
    },
    onAppointmentCreated({commit}, data) {
      commit(SET_APPOINTMENT, data)
      //dispatch('refreshAppointmentLists', data.customer)
    },
    onAppointmentUpdated({commit}, data) {
      commit(SET_APPOINTMENT, data)
      //dispatch('refreshAppointmentLists', data.customer)
    },
    async refreshAppointmentLists({dispatch, state}, customerId) {
      if (customerId && state.currentCustomerId === customerId) {
        await dispatch('fetchCustomerAppointments', customerId)
      }
      if (state.currentDate) {
        await dispatch('fetchAppointmentsByDate', {})
      }
      // if (Object.keys(state.filter).length) {
      //   await dispatch('fetchAppointments')
      // }
      // TODO check if there is a need to refresh appointments list
    }
  }
}
