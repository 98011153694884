<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'sms_composer.customer_composer_modal_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.is-flex.p-0.is-flex-direction-column
    .p-3.has-border-bottom
      customer-tiny-preview(:value="customer" size="large")
    CustomerMessages.is-flex-grow-1(:customer="customer")
</template>
<script>
import CustomerMessages from './CustomerMessages'
export default {
  name: 'CustomerSMSModal',
  components: {
    CustomerMessages
  },
  props: {customer: {type: Object, required: true}}
}
</script>
